body {
    color: #fff;
    font-family: 'unset';
    margin: 0;
}

.formfield {
    margin: 10px;
}



.close{
    background: #fff;
    height: 30px;
    width: 30px;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    top: -15px;
    right: -15px;
    font-size: 20px;
    text-decoration: none;
}

/* .App{
    background: url('./assets/intro-bg.gif');
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
} */

/* Quiz page */

span.coming-text {
    font-size: 12px;
    padding: 4px;
    border: 1px solid;
    background: gray;
    border-radius: 5px;
    margin-left: 5px;
    color: #fff;
}

.plan-page p,
.register-page p {
    color: #c9c7c3;
}

.panel {
    min-width: 95%;
    height: 95VH;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    border-radius: 30px;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
}

.panel-right {
    width: 50%;
    height: 100%;
    position: relative;
    background-image: url('./assets/planbg.PNG');
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 30px;
    padding: 50px;
    font-family: 'Roboto';
}

.panel-right h1 {
    font-size: 40px;
    margin: 0;
    color: #fff;
}

.panel-right p {
    font-weight: 100;
    font-size: 20px;
    margin-top: 10px;
    color: #fff
}

.panel-left {
    position: relative;
    width: 50%;
    height: 100%;
    padding: 0 50px;
    max-width: 500px;
    /* overflow-y: scroll; */
}

.panel-right * {
    position: relative;
}

.plan-container {
    max-width: 500px;
}

.plan {
    padding: 10px;
    border: 1px solid #000;
    border-radius: 16px;
    background: grey;
    max-height: 100px;
    overflow: hidden;

    margin-bottom: 20px;
    background: linear-gradient(90deg, #818183, #232230);
    border: 1px solid #4C4B5D;
    transition: max-height 2s ease;
}

.plan:hover {
    max-height: 500px;
    background: linear-gradient(90deg, #5d5858, #353443);
    /* transition: max-height 3s ease-in-out; */
}

.recom:hover {
    background: linear-gradient(45deg, #e3a12b, #232230);
}

.plan.selected {
    border: 2px solid;
    box-shadow: 0px 0px 10px 2px #5d5858;
}

.recom.selected {
    border: 2px solid;
    box-shadow: 0px 0px 10px 2px #e3a12b;
}

.plan-list {
    display: flex;
}

.plan-list p {
    flex: 1;
    /* list-style-type: circle; */
    /* padding-left: 15px; */
    display: flex;
}

.plan-list p:before {
    content: "";
    width: 14px;
    height: 15px;
    /* border: 1px solid forestgreen; */
    margin-right: 10px;
    display: inline-block;
    border-radius: 100%;
    background: #cdc6c6;
}

.plan-amt {
    padding-top: 0;
    margin-left: 15px;
}

.recom {
    background: linear-gradient(45deg, #d9a851, #232230);
}

.plan:nth-child(3) h2 {
    margin-bottom: 20px;
}

/* .recom:after {
    content: "Recommend";
    position: absolute;
    right: 10px;
    background: #ECAE0D;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    bottom: -10px;
} */

.plan-amt h5 {
    color: #c9c7c3;
}

.plan h1,
.plan h2,
.plan h3,
.plan h4,
.plan h5,
.plan h6 {
    margin: 0 0 10px 0
}

.plan h2 {
    color: #E8E7E0;
    background: linear-gradient(356deg, #fffce7, #95918C);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 30px;
}

.recom h2 {
    background: linear-gradient(90deg, #FFF50A 0%, #FFFFF7 60%);
    background-clip: text;
}

.plan-body {
    display: flex;
}

.plan-body p {
    font-size: 14px;
    color: #bdbbb8;
}

.plan-body button {
    background: #0d99ff;
    color: #fff;
}

.plan-footer {
    border-top: 1px solid #c7c6c6;
    padding-top: 15px;
}

.plan-footer p {
    color: #e7e1e1;
    font-size: 14px;
    margin: 0 0 10px;
}

.plan-footer ol {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
}

.plan-btn {
    background: #0d99ff;
    width: 100%;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.button {
    background: #0d99ff;
    width: auto;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

/* Register Page */
.selected-plan {
    font-size: 24px;
    margin-bottom: 20px;
    background: linear-gradient(90deg, #818183, #232230);
    border: 1px solid #4C4B5D;
    transition: max-height 3s ease-in-out;
    padding: 20px;
    border-radius: 16px;
}

input {
    width: 100%;
    padding: 10px 15px;
    border-radius: 8px;
}

.register-btn {
    background: #0d99ff;
    width: 100%;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin-top: 20px;
}

.register-btn.disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.register-form p {
    margin-bottom: 5px;
}



.leader-page {
    display: flex;
    background: white;
    margin: auto;
    border-radius: 30px;
    transform: translate(0, 0);
    position: relative;
    left: 0;
    top: 0;
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    backdrop-filter: blur(1rem);
    /* height: 60vh; */
    margin: 20px;
    justify-content: center;
    align-items: center;
    padding: 25px;
    height: -webkit-fill-available;
    min-width: auto;
}

.leadBtn-grp {
    flex-direction: column;
}

.leader-shark {
    width: 175px;
    position: absolute;
    top: -30px;
}

.panel-header {
    height: 160px;
    position: relative;
    margin: 10px;
    box-shadow: 0 0 6px 1px #1f415f;
    border-radius: 30px;
}

.panel-body {
    min-height: calc(85vh - 400px);
    position: relative;
    margin: 20px 10px 10px;
    box-shadow: 0 0 6px 1px #1f415f;
    border-radius: 30px;
    overflow: hidden;
}

img.leader-logo {
    position: absolute;
    width: 100px;
    top: 35%;
    right: 5%;
}

.row-header {
    display: flex;
    padding: 10px;
    margin: 10px;
}

.rank {
    flex: 0 100px;
    padding-left: 20px;
}

.name {
    flex: 0 300px;
}

.score {
    flex: 1;
}

.result {
    flex: 0 100px;
}

.row {
    display: flex;
    padding: 20px 10px;
    border-radius: 10px;
    margin: 10px;
    background: linear-gradient(90deg, rgba(31, 65, 95, 0.1) 0%, rgba(0, 0, 0, 0.1) 40%);
    /* -webkit-backdrop-filter: blur(1rem); */
    /* backdrop-filter: blur(1rem); */
    font-size: 20px;
}

.row:first-child {
    background: linear-gradient(90deg, rgba(255, 215, 0, 0.5) 0%, rgba(0, 0, 0, 0.1) 40%);
}

.row:nth-child(2) {
    background: linear-gradient(90deg, rgba(192, 192, 192, 0.5) 0%, rgba(0, 0, 0, 0.1) 40%);
}

.row:nth-child(3) {
    background: linear-gradient(90deg, rgba(205, 127, 50, 0.5) 0%, rgba(0, 0, 0, 0.1) 40%);
}

.leader-result {
    overflow-y: scroll;
    height: 40vh;
}

span.right {
    width: 20px;
    height: 20px;
    background: green;
    display: inline-block;
    margin: 0 5px;
    border-radius: 100%;
}

span.wrong {
    width: 20px;
    height: 20px;
    background: red;
    display: inline-block;
    margin: 0 5px;
    border-radius: 100%;
}

.leader-text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    flex-direction: column;
}

.panel-footer .button {
    width: 200px;
    margin-left: auto;
    margin-right: 10px;
}

.change-btn {
    height: auto;
    padding: 5px 10px;
    font-size: 14px;
}

.plan-details {
    display: flex;
    ALIGN-ITEMS: center;
    justify-content: space-between;
}

.plan-info .plan {
    max-height: none;
}

.mobile-view .row {
    display: flex;
    flex-direction: column;
    font-size: 16px;
}

.mobile-view .row>div {
    display: flex;
    /* flex-direction: column;
    flex: 0; */
}

.mobile-view .row>.score {
    margin-top: 15px;
    flex-direction: column;
    flex: 0;
}

.mobile-view .row>.score h4 {
    margin: 0 0 15px 0;
}

.mobile-view {
    display: none;
}

.header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
}

.dashboardPage {
    height: 100%;
    overflow: auto;
    background-color: #23477d;
}

.home-logo {
    width: 100px;
}

.bannersection {
    margin: 20px;
}

.banner {
    height: 400px;
    /* margin: 20px; */
    background: #2B1963;
    border-radius: 10px;
    /* BACKGROUND: #6992BD; */
    /* background: #2D106B; */
    background-image: linear-gradient(#2B1963 0%, #2D106B 90%);
    overflow: hidden;
    position: relative;
    display: flex;
}

.banner:after {
    content: '';
    width: 300px;
    height: 100%;
    background: rgb(0, 212, 255);
    background: radial-gradient(circle, rgba(0, 212, 255, 0.4) 0%, transparent 50%);
    position: absolute;
    right: 0;
    mix-blend-mode: screen;
    border-radius: 50%;
    top: 50%;
    backdrop-filter: blur(1rem);
    transform: translate(0px, -50%);
}

/* .banner:before {
    content: '';
    width: 300px;
    height: 100%;
    background: rgb(138,67,171);
    background: radial-gradient(circle, rgba(138,67,171, 0.6) 0%, transparent 50%);
    position: absolute;
    left: 10%;
    mix-blend-mode: screen;
    border-radius: 50%;
    top: 50%;
    backdrop-filter: blur(1rem);
    transform: translate(-10%, -50%);
} */

.silver-banner {
    background: linear-gradient(90deg, rgba(192, 192, 192, 0.5) 0%, rgba(31, 65, 95, 0.1) 80%);
}

.silver-banner .banner-text,
.gold-banner .banner-text,
.diamond-banner .banner-text {
    flex-direction: column;
}

.silver-banner .banner-text h1,
.gold-banner .banner-text h1,
.diamond-banner .banner-text h1 {
    font-size: 36px;
}

.silver-banner .banner-text h6,
.gold-banner .banner-text h6,
.diamond-banner .banner-text h6 {
    margin: 0;
    font-size: 24px;
}

.banner-prize {
    display: flex;
    align-items: center;
}

img.prize-ticket {
    height: 200px;
}

.gold-banner {
    background: linear-gradient(90deg, rgba(255, 215, 0, 0.5) 0%, rgba(31, 65, 95, 0.1) 80%);
}

.diamond-banner {
    background: linear-gradient(90deg, rgba(205, 127, 50, 0.5) 0%, rgba(31, 65, 95, 0.1) 80%);
}

.banner video {
    position: absolute;
    bottom: -13%;
    z-index: 1;
    height: 100%;
    right: 0;
    width: 40%;
    text-align: right;
    display: flex;
    margin-right: auto;
    overflow: hidden;
}

.banner-text {
    font-size: 36px;
    width: 40%;
    /* position: absolute; */
    margin-left: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.dashboardPage .plans,
.dashboardPage .quizs {
    margin: 20px;
}

.card-grp {
    display: flex;
}

.card-grp>div {
    margin-right: 20px;
    position: relative;
}

.plancard-grp {
    display: flex;
    justify-content: space-around;
    flex: 1;
    gap: 15px;
}

img.dropdown-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid #2c1864;
}

.banner .sharkGif {
    width: auto !important;
    z-index: 1;
    margin-left: auto;
}

/* .carousel {
    display: none;
}

.carousel.carousel-slider {
    display: block;
} */

.button-container>.button.disabled {
    background: gray;
    cursor: not-allowed;
    background-color: #cccccc;
    color: #666666;
}

.textfield input {
    background-color: #fff !important;
}


@media (max-width: 991px) {
    .panel {
        flex-direction: column-reverse;
    }

    .panel-right {
        width: 100%;
        height: 200px;
        padding: 20px;
    }

    .panel-left {
        width: 100%
    }

    /* .desktop-view {
        display: none;
    }

    .mobile-view {
        display: block;
    } */

    .row-header {
        display: none;
    }

    .leader-logo {
        display: none;
    }

    .leader-shark {
        width: 100px;
    }

    .leader-text {
        margin-left: 120px;
    }

    .leader-text h1 {
        margin: 10px 0;
    }

    .rank {
        flex: 0 1 50px;
        padding-left: 0;
    }

    .score {
        display: flex;
        align-items: center;
        margin-right: 10px;
    }

    .leader-page {
        flex-direction: column;
    }

    span.right,
    span.wrong {
        width: 10px;
        height: 10px;
        margin: 0 2px;
    }

    .panel-left {
        padding: 0 10px;
        overflow: auto;
    }

    .card-grp,
    .plancard-grp {
        display: flex;
        flex-direction: column;
    }

    .card-grp>div {
        margin-right: 0;
    }

    .card {
        width: 100% !important;
    }



    .banner video {
        display: none;
    }

    .banner-text {
        font-size: 24px;
        width: 100%;
        margin-right: 10%;
    }

    img.prize-ticket {
        height: 120px;
    }

    .banner .sharkGif {

        display: none;
    }
}

.homePage {
    overflow: auto;
    height: 100%;
    background-image: url('./assets/coast.png');
    background-position: center;
}

.homePage .bannersection {
    background-image: url('./assets/coast-bg.png');
    height: 800px;
    margin: 0;
    background-position: top center;
    background-size: cover;
    position: relative;
    overflow: hidden;
}

.homePage .bannersection:after {
    content: '';
    background-image: url('./assets/coast-bottom-bg.png');
    height: 200px;
    margin: 0;
    background-position: top center;
    background-size: cover;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    /* height: 150px; */
    background-repeat: no-repeat;
    margin: 0 -5px;
}

.homePage .banner-inner {
    width: 80%;
    height: 50%;
    border: 1px solid #dfdede;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    margin: 5% 10% 10% 10%;
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2));
    -webkit-backdrop-filter: blur(0.2rem);
    backdrop-filter: blur(0.2rem);
    border-radius: 20px;
    flex-direction: column;
    padding: 20px;
    font-family: 'Roboto';
}

.homePage .banner-inner h1 {
    font-size: 60px;
    margin: 0;
    text-shadow: 1px 1px 4px #cbb8b8;
}

.homePage .banner-inner h4 {
    font-size: 40px;
    margin: 0;
    font-weight: 200;
    text-shadow: 1px 1px 4px #cbb8b8;
}

.homePage .banner-inner .shark-img {
    position: absolute;
    bottom: 0;
    height: 100%;
    right: 0;

}

.homePage .banner-inner .button-grp {
    margin-top: 20px;
}

.btn-white {
    color: #fff !important;
    border-color: #fff !important;
}

.homePage .banner-inner .button-grp button:first-child {
    margin-right: 20px;
}

.homePage .banner-inner .banner-count {
    display: flex;
    align-items: center;
    /* justify-content: center; */
}

.homePage .banner-inner .count-text {
    margin-left: 10px;
    font-family: 'Roboto';
    font-weight: 100;
}

.homePage .plans,
.homePage .quizs,
.homePage .about,
.homePage .rules {
    margin: 100px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto';
    font-weight: 100;
}

.homePage .plans h1,
.homePage .quizs h1,
.homePage .about h1,
.homePage .rules h1 {
    font-family: 'Roboto';
    font-weight: 100;
    background: linear-gradient(to right, rgba(255, 255, 247, 1), rgba(255, 255, 247, 0.34));
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.homePage .about p {
    color: gray;
    width: 80%;
    text-align: center;
}

.homePage .plans .plan-container {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
}

.homePage .plans .plan-container .plan {
    flex: 1;
    margin: 10px;
    max-height: 100%;
    background: linear-gradient(135deg, rgba(24, 27, 48, 0.3) 0%, rgba(36, 43, 113, 0.3) 100%);
    border-color: rgba(0, 71, 255, 0.25);
}

.homePage .plans .plan-container .plan .plan-body h2 {
    background: linear-gradient(90deg, #FFFFF7 0%, rgba(55, 255, 10.0.34) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}


.homePage .quizs .card-grp {
    display: flex;
    width: 100%;
    overflow: auto;
    margin: 20px 0;
    padding-bottom: 20px;
}

.homePage .quizs .card-grp::-webkit-scrollbar {
    width: 12px;
    /* width of the entire scrollbar */
}

.homePage .quizs .card-grp::-webkit-scrollbar-thumb {
    background-color: #111430;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    /* border: 3px so    */
}

.homePage .quizs .card-grp::-webkit-scrollbar-track {
    background: transparent;
    margin-top: 20px;
    /* color of the tracking area */
}

.linkDisplay {
    display: flex;
    flex: 1;
    text-align: center;
    justify-content: center;
    color: #fff;
    font-size: 20px;
}

.mobile-only {
    display: none;
}

.desktop-only {
    display: block;
}

@media (max-width: 991px) {
    .desktop-only {
        display: none;
    }

    .mobile-only {
        display: block;
    }

    .homePage .banner-inner h1 {
        font-size: 30px;
    }

    .homePage .banner-inner h4 {
        font-size: 20px;
    }

    .homePage .banner-inner .shark-img {
        display: none;
    }

    .homePage .banner-inner {
        height: auto;
        margin: 5% 5% 150px 5%;
        padding: 12px;
        width: 90%;
    }

    .homePage .banner-inner .person {
        width: 25px;
        height: 25px;
    }

    .homePage .bannersection {
        height: auto;
    }

    .homePage .bannersection:after {
        height: 100px;
    }


    .homePage .plans,
    .homePage .quizs,
    .homePage .about,
    .homePage .rules {
        margin: 100px 10px;
    }

    .homePage .plans .plan-container {
        flex-direction: column;
    }
}

.slide {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* deskHome */

/* .deskHome {
    height: 100%;
    width: 100%;
    overflow-x: auto;
}

body {
    margin: 0;
    padding: 0;
} */

.deskHome .sharkProfile {
    display: none;
}

.page-content-title {
    color: #fff !important
}

.mainsection .header {
    position: absolute;
    top: 0;
}

.about {
    color: #fff;
    font-family: "Roboto";
    line-height: 1.5;
}

.deskHome {
    position: relative;
    width: 100%;
    height: 100vh;
    scroll-behavior: smooth;
    overflow: auto;
    scroll-snap-type: y mandatory;
    background: #13568a;
    color: #fff;

}

.section {
    width: 100%;
    min-height: 100vh;
    background-size: cover;
    scroll-snap-align: start;
    /* overflow-x: hidden; */
}

.section:nth-child(2) {
    /* background: url(https://cdn.pixabay.com/photo/2015/06/02/12/59/narrative-794978_960_720.jpg); */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    overflow-x: hidden;
}

.section:nth-child(2) h1 {
    /* background: url(https://cdn.pixabay.com/photo/2015/06/02/12/59/narrative-794978_960_720.jpg); */
    /* display: flex; */
    /* justify-content: center; */
    align-items: center;
    overflow-x: hidden;
}

.section:nth-child(3) {
    /* background: url(https://cdn.pixabay.com/photo/2015/12/19/20/32/paper-1100254_960_720.jpg); */
}

.section:nth-child(4) {
    /* background: url(https://cdn.pixabay.com/photo/2016/03/26/22/21/books-1281581_960_720.jpg); */
    background-image: url('./assets/parallex/bg/home2-layer2.png');
    overflow: hidden;
}

.section:nth-child(5) {
    /* background: url(https://cdn.pixabay.com/photo/2014/05/03/00/46/notebook-336634_960_720.jpg); */
    background-image: url('./assets/parallex/bg/home2-layer2.png');
    /* scroll-snap-align: unset; */
    /* overflow: hidden; */
}

.PhoneInput {
    background: #fff;
    color: #111;
    padding: 13px 12px 8px;
    border-radius: 8px;
    margin-top: 18px;
    margin-bottom: 15px;
}

.PhoneInputInput {
    flex: 1 1;
    min-width: 0;
    border: none;
    padding: 0;
    height: 35px;
    /* font-size: 25px; */
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
}

.PhoneInputInput:focus-visible {
    outline: none;
}

.PhoneInputCountryIcon {
    height: auto;
    width: 100%;
    box-shadow: none;
}

.PhoneInputCountry {
    width: 50px;
}

.passwordHint {
    border: 1px solid #000;
    background: #ddd;
    padding: 10px;
    margin-top: 15px;
    border-radius: 8px;
    color: #111;
    font-family: "Roboto";
}

.verifyAcc {
    color: #fff;
    font-size: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 15px 0;
    text-decoration: underline;
}

.section-title {
    display: flex;
    justify-content: center;
    padding: 20px;
    text-decoration: underline;
    font-family: "Roboto";
    color: #fff;
}

.header {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    /* position: absolute; */
    /* top: 0; */
    width: 100%;
    /* z-index: 2;
    left: 0;
    right: 0; */
    /* height: 200px; */
}

.right-side-item {
    background: #fff;
    border-radius: 10px;
    padding: 10px;
}

.right-side-item a {
    color: #1c2343;
    font-family: "Roboto";
    padding: 0 10px;
    transition: all .2s;
}

.right-side-item a:hover {
    transform: scale(1.5);
    text-decoration: underline;
}

.info-container {
    height: 300px;
    /* background-color: red; */
    margin: 20px;
    display: flex;
    border-radius: 20px;
    /* background-image: linear-gradient(45deg, #fff, transparent); */
}

.sharkProfile {
    border-radius: 50%;
    border: 1px solid #fff;
    /* margin: 20px 10px; */
    margin: 30px 30px 30px 0;
    box-shadow: 0px 0px 24px 5px #fff;
    background: #fff;
}

.dashboard-card-container {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    padding: 20px 0;
}

.dashboard-card-container>div {
    flex: 33.33%;
}

.dashboard-card {
    display: flex;
    width: 100%;
    /* width: 25%; */
    box-sizing: border-box;
}

.dashboard-inner {
    padding: 20px;
    /* border: 1px solid #4b92c9; */
    border-radius: 20px;
    margin: 10px;
    background-color: #4b92c9;
    color: #fff;
    width: 100%;
    position: relative;
    background: #7DA0FA;
    color: #ffffff;
}

.plan-bg .dashboard-inner {
    /* background: #7DA0FA;
    color: #ffffff; */
    background: #a8517e;
    color: #fff;
}

.plan-bg .dashboard-inner:after {
    content: "";
    background-image: url("./assets/dashboard/fin.png");
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: right;
    opacity: 0.5;
    background-size: 100px;
    background-repeat: no-repeat;
}

.quiz-bg .dashboard-inner {
    /* background: #4747A1;
    color: #ffffff; */
    background: #a0b43b;
    color: #ffffff;
}

.quiz-bg .dashboard-inner:after {
    content: "";
    background-image: url("./assets/dashboard/cricket.png");
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: right;
    opacity: 0.5;
    background-size: 100px;
    background-repeat: no-repeat;
}

.part-bg .dashboard-inner {
    /* background: #7978E9;
    color: #ffffff; */
    background: #8868a3;
    color: #ffffff;
}

.part-bg .dashboard-inner:after {
    content: "";
    background-image: url("./assets/dashboard/rank.png");
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: 90%;
    opacity: 0.5;
    background-size: 65px;
    background-repeat: no-repeat;
}

.next-bg .dashboard-inner {
    background: #F3797E;
    color: #ffffff;
}

.next-bg .dashboard-inner:after {
    content: "";
    background-image: url("./assets/dashboard/cal.png");
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: right;
    opacity: 0.5;
    background-size: 80px;
    background-repeat: no-repeat;
}

.total-bg .dashboard-inner {
    background: #7374ac;
    color: #ffffff;
}

.total-bg .dashboard-inner:after {
    content: "";
    background-image: url("./assets/dashboard/participant.png");
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 100px;
    opacity: 0.4;
    background-repeat: no-repeat;
}

.prize-bg .dashboard-inner {
    background: #98a1b1;
    color: #ffffff;
}

.prize-bg .dashboard-inner:after {
    content: "";
    background-image: url("./assets/dashboard/coins.png");
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: right;
    opacity: 0.5;
    background-size: 80px;
    background-repeat: no-repeat;
}

.dashboard-inner h3 {
    margin: 0 0 10px 0;
    font-family: "Roboto";
}

.dashboard-inner h1 {
    margin: 0;
    font-family: "Roboto";
    font-weight: normal;
}

.backdrop-container {
    display: none;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-animation: fadeIn 1.2s ease-in-out;
    animation: fadeIn 1.2s ease-in-out;
}

nav {
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 1;
}

.mobile-nav-open-icon {
    font-size: 2rem;
    cursor: pointer;
    margin-right: 2rem;
    color: black;
    margin-left: 3rem;
}

.sidenav-container {
    height: 100%;
    width: 0px;
    position: fixed;
    z-index: 3;
    top: 0;
    right: 0;
    background-color: #13568a;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-left: 1px solid #f5f5f5;
}

.sidenav-container a {
    text-decoration: none;
    font-size: 1rem;
    color: #818181;
    display: block;
    transition: 0.3s;
    margin: 10px 0;
}

.sidenav-container a:hover {
    color: #f1f1f1;
}

.sidenav-container .closebtn {
    font-size: 3rem;
    font-weight: 700;
    color: #fff;
    padding-right: 1rem;
}

.sidenav-container .drawer-close-button {
    height: 3rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1rem;
}

.leaderboard-carousal {
    /* margin: 0 20px; */
    /* border: 1px solid #fff; */
    padding: 0 20px;
    border-radius: 20px;
    width: 65%;
    /* background-color: #fff; */
}

.d-flex {
    display: flex;
    margin: 20px;
}

.diamond-tier-adv {
    flex: 1;
    /* height: 400px; */
    margin-right: 20px;
    background-color: #fff;
    border-radius: 10px;
    color: #000;
    padding: 20px !important;
    font-family: "Roboto";
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.diamond-tier-adv h3 {
    font-weight: normal;
}

.diamond-tier-adv img {
    width: 100%;
}

.leaderboard-carousal .review.item {
    background-image: none;
    border: none;
    border-radius: 30px;
    padding: 0 20px;
    margin: 0;
}

.leaderboard-carousal .owl-nav {
    position: absolute;
    right: 0;
    top: 0;
}

button.owl-prev,
button.owl-next {
    width: 30px;
    height: 30px;
    background: #ecedf2 !important;
    color: gray !important;
}

.carQuizBtn {
    background: #fff;
    color: #0d99ff;
    width: auto;
    height: 50px;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    /* width: 100px; */
    margin-top: 30px;
    animation: pulseWhite 1s infinite;
    box-shadow: 0 20px 40px #0006;
    box-shadow: 0 0 0 2em #0000;
    transform: scale(1.05);
}

.carBtn {
    background: #0d99ff;
    width: 100%;
    height: auto;
    font-size: 16px;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    /* width: 100px; */
    margin-top: 30px;
    animation: pulse 1s infinite;
    box-shadow: 0 20px 40px #0006;
    box-shadow: 0 0 0 2em #0000;
    transform: scale(1.05);
}

@keyframes pulseWhite {
    0% {
        box-shadow: 0 0 0 0 #fff;
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 #3289ef;
    }
}

.dashboard-user {
    margin: 20px;
    font-size: 24px;
    font-family: "Roboto";
    font-weight: 800;
}

.top-score {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.you-rank {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 200px;
}

.you-rank:nth-child(2) {
    margin-top: 20px;

}

.you-rank h3,
.you-rank h1 {
    margin: 0 0 10px 0;
    color: #000;
    font-family: "Roboto";
}

.quiz-dashboard-container {
    margin: 20px;
}

.quiz-dashboard-container img {
    width: 200px !important;
}

.quiz-dashboard-container h1 {
    color: #fff;
}

.quiz-dashboard-container .review.item {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.4);
}

.dashboard-card-container-desk {
    display: flex;
}

.dashboard-card-container-mobile {
    display: none;
}
.deskHome .sharkProfile {
    width: 200px;
    display: flex;
    margin-right: 0;
}

@media (max-width: 991px) {
    .info-container {
        flex-direction: column;
        align-items: center;
        height: auto;
    }

    .sharkProfile{
            width: 200px;
            display: flex;
            margin-right: 0;
    }
    

    .dashboard-card-container-desk {
        display: none;
    }

    .dashboard-card-container-mobile {
        display: flex;
    }

    .dashboard-card-container {
        width: 100%;
    }

    .d-flex {
        flex-direction: column;

    }

    .d-flex>div {
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
    }

    .you-rank {
        width: 100%;
    }

    .leaderboard-carousal .review.item {
        padding: 0;
    }

    #leaderboard {
        width: 100%;
        position: relative;
        border-radius: 20px;
        padding-bottom: 20px;
    }

    .you-rank:nth-child(2) {
        margin-top: 0px;
        margin-left: 20px;
    }

    .rank-panel {
        display: flex;
    }

    .page1-content1.center {
        top: 35%;
        z-index: 4;
    }
}

.logout {
    /* background: #0d99ff; */
    width: auto;
    /* height: 50px; */
    font-size: 12px !important;
    color: #fff !important;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-color: #fff !important;
    flex-direction: column;
}

.menuBtn {
    font-size: 24px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 10px;
    margin-bottom: 5px;
    background: rgba(0, 0, 0, 0.3);
}

.mainsection {
    background-image: url('./assets/parallex/bg/Layer1\ 2.jpg');
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.section2 {
    background-image: url('./assets/parallex/bg/Main\ layer\ 3.png');
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    overflow: hidden;
    position: relative;
    flex-direction: column;
}



.home2-container {
    width: 700px;
    /* height: 300px; */
    position: relative;
    margin-top: 200px;
}

.home2-container h1,
.home2-container h2 {
    font-size: 65px;
    text-transform: uppercase;
}

.home2-container h1 span,
.home2-container h2 span {
    width: 100%;
    float: left;
    color: #ffffff;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
    transform: translateY(-50px);
    opacity: 0;
    font-family: "Rubik", sans-serif;
}

.home2-container h1 span {
    animation-delay: 0.6s;
    -webkit-animation-fill-mode: forwards;
}

.home2-container h1 span:first-child {
    animation-delay: 0.7s;
}

.home2-container h1 span:last-child {
    color: #ffe221;
    animation-delay: 0.5s;
}

.home2-container h2 {
    top: 0;
    position: absolute;
}

.home2-container h2 span {
    animation-delay: 4.1s;
    -webkit-animation-fill-mode: forwards;
}

.home2-container h2 span:first-child {
    animation-delay: 4.2s;
}

.home2-container h2 span:last-child {
    color: #ffe221;
    animation-delay: 4s;
}

.home2-container .usechrome {
    font-size: 10px;
    color: #fff;
    font-family: helvetica, arial;
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
    left: 0;
}

.animation span {
    animation: titleAnimation 3s steps(15);
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
}

.animatebtn {
    display: none;
}

#text {
    font-size: 33px;
    font-family: "Roboto";
    font-weight: bold;
    color: #ffffff;
    text-shadow: 2px 2px #d14242;
}

.animation .animatebtn {
    transition: all 1s ease-in-out;
    transition-delay: 20s;
}


@keyframes titleAnimation {
    0% {
        transform: translateY(-50px);
        opacity: 0;
        -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
        clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
    }

    20% {
        transform: translateY(0);
        opacity: 1;
        -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
        clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
    }

    80% {
        transform: translateY(0);
        opacity: 1;
        -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
        clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
    }

    100% {
        transform: translateY(50px);
        opacity: 0;
        -webkit-clip-path: polygon(100% 0, 100% 0%, 0 100%, 0 100%);
        clip-path: polygon(100% 0, 100% 0%, 0 100%, 0 100%);
        display: none;
    }
}

.page1-content1.center {
    position: absolute;
    width: 70%;
    margin-top: 20px;
    top: 50%;
    transform: translateY(-50%);
}

.goldenCoral-container {
    position: absolute;
    bottom: 0;
    /* height: 184px; */
    left: 10%;
    height: 300px;
    /* right: 100px; */
    /* width: 100%; */
    transform: scale(-1, 1);
}

@media (max-width: 991px) {

    .section:nth-child(4),
    .section:nth-child(5),
    .section2 {
        background-image: url('./assets/parallex/bg/Mobile\ Design.png');
        background-position: left;
    }

    .home2-container {
        width: 100%;
        /* height: 300px; */
        position: relative;
        margin-top: 200px;
        text-align: center;
    }

    .home2-container h2,
    .home2-container h1 {
        font-size: 40px;
        text-transform: uppercase;
    }

    .faqs {
        padding-top: 0 !important;
    }

    .section-title {
        padding: 0px;
    }

    .silverCoral-container {
        display: none;
    }

    .goldenCoral-container {
        transform: none;
        width: 100%;
    }
}

.plancardnew {
    position: relative;
    /* max-width: 300px; */
    height: auto;
    background: linear-gradient(-45deg, #fe0847, #feae3f);
    border-radius: 15px;
    margin: 0 auto;
    padding: 40px 20px;
    -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, .1);
    box-shadow: 0 10px 15px rgba(0, 0, 0, .1);
    -webkit-transition: .5s;
    transition: .5s;
    /* overflow: hidden; */
    /* display: flex;
    flex-direction: column; */

}

.passwordHint ul {
    list-style: none;
}

.plancardnew:hover,
.plancardnew.activePlan {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.silverPlan.plancardnew,
.silverPlan.plancardnew .title .fa {
    background: linear-gradient(-45deg, #f403d1, #64b5f6);

}

.goldPlan.plancardnew,
.goldPlan.plancardnew .title .fa {
    background: linear-gradient(-45deg, #ffec61, #f321d7);
}

.diamondPlan.plancardnew,
.diamondPlan.plancardnew .title .fa {
    background: linear-gradient(-45deg, #24ff72, #9a4eff);
}

.plancardnew::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background: rgba(255, 255, 255, .1);
    z-index: 0;
    -webkit-transform: skewY(-5deg) scale(1.5);
    transform: skewY(-5deg) scale(1.5);
}

.title .fa {
    color: #fff;
    font-size: 60px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    text-align: center;
    line-height: 100px;
    -webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, .1);
    box-shadow: 0 10px 10px rgba(0, 0, 0, .1);

}

.title h2 {
    position: relative;
    margin: 20px 0 0;
    padding: 0;
    color: #fff;
    font-size: 28px;
    z-index: 2;
}

.price,
.option {
    position: relative;
    z-index: 2;
}

.price h4 {
    margin: 0;
    padding: 20px 0;
    color: #fff;
    font-size: 40px;
}

.price.coupon-applied h4 span:first-child{
    text-decoration:line-through;
    margin-right: 10px;
}

.price h4 span.discounted-amt {
    display: none;
}

.price.coupon-applied h4 span.discounted-amt {
    display: inline-block;
}

.coupon-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.coupon-title > div {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    padding: 10px 20px;
    color: #000;
    border-radius: 20px;
}

.coupon-title > div > button{
    padding: 0 0 0 10px;
    min-width: 30px;
}

.coupon-btn {
    color: #fff !important;
    border-color: #fff !important;
    border-width: 3px !important;
}

.coupon-btn:hover {
    text-decoration: none;
    background-color: rgba(46, 125, 50, 0.04);
    border-color: #fff !important;
    border-width: 3px !important;
    box-shadow: 0 0 6px 3px #fff !important;
}

.option ul {
    margin: 0;
    padding: 0;

}

.option ul li {
    margin: 0 0 10px;
    padding: 0;
    list-style: none;
    color: #fff;
    font-size: 16px;
}

.plancardnew a {
    position: relative;
    z-index: 2;
    background: #fff;
    color: black;
    width: 150px;
    height: 40px;
    line-height: 40px;
    border-radius: 40px;
    display: block;
    text-align: center;
    margin: 20px auto 0;
    font-size: 16px;
    cursor: pointer;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);

}

.plancardnew a:hover {
    text-decoration: none;
}

.col-sm-4 {
    flex: 33%;
}

.plansRow {
    display: flex;
    flex: 1;
    margin: 0 10%;
    overflow: hidden;
}

.plansRow .review.item {
    background: none;
    border: none;
    padding-top: 50px;
}

.plans {
    height: 100%;
    width: 100%;
    display: flex;
    /* justify-content: center;
    align-items: center; */
    flex-direction: column;
    background-color: #13568a;
}

.plans .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1
}

@media (max-width: 991px) {
    .plansRow {
        flex-direction: column;
    }

    .col-sm-4 {
        margin-bottom: 20px;
    }

    /* .section {
        height: auto;
    } */
}


.page1-content1 h2 {
    width: 70%;
    font-size: 30px;
    display: block;
    text-align: center;
}

.char {
    font-size: 40px;
    height: 40px;
    display: inline-block;
}

.animation #text {
    animation: an 1s ease-out 1 both;
    animation-delay: 7s !important;
}

@keyframes an {
    from {
        opacity: 0;
        transform: perspective(500px) translate3d(-35px, -40px, -150px) rotate3d(1, -1, 0, 35deg);
    }

    to {
        opacity: 1;
        transform: perspective(500px) translate3d(0, 0, 0);
    }
}

.plans .container {
    overflow: auto;
    align-items: normal;
}

.sidenav-container h1 {
    color: #fff;
}

img.profileImage {
    width: 150px;
    border-radius: 50%;
    height: 150px;
    border: 1px solid #fff;
    box-shadow: 0px 0px 24px 5px #fff;
    margin: 20px;
    background: #fff;
}

li.dashboard-plan {
    list-style: none;
    font-size: 24px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 10px;
    margin-bottom: 5px;
    background: rgba(0, 0, 0, 0.3);
    margin-top: 10px;
}

.icon {
    margin-right: 10px;
}

.icon.diamond-icon {
    color: #b9f2ff;
}
.icon.silver-icon {
    color: #C0C0C0;
}
.icon.gold-icon {
    color: #FFD700;
}

.left-side {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.left-side button {
    color: #fff !important;
    border-color: #fff !important;
}

.drawer-menu .MuiDrawer-paperAnchorLeft {
    background-color: #13568a;
    color: #fff
}

.drawer-menu .MuiDrawer-paperAnchorLeft i{
    /* background-color: #13568a; */
    color: #fff
}

.drawer-menu .MuiBox-root {
    padding-top: 40px;
}

.showPassword {
    position: absolute;
    top: 40%;
    color: #13568a;
    right: 4%;
    cursor: pointer;
}

.formfield{ position: relative;}

.dashboard-plans-container{
    display: flex;
    gap: 10px
}

.dashboard-plans-container > div {
    width: 33% !important;
}

.carBtn:disabled {
    cursor: not-allowed;
    animation: none;
    background: #0062a9;
}

.quizPanel.blogPanel .carBtn-grp > button {
    margin-top: 10PX;
    margin-bottom: 10px;
}

.quizPanel.blogPanel ol li {
    margin-bottom: 10px;
}

@media (max-width: 991px) {
    .blogPanel .quizPanel-left {
        height: auto;
    }

    .blogPanel .quizPanel-section {
        flex-direction: column;
        padding: 30px;
    }

    .quizPanel.blogPanel {
        transform: none;
        position: relative;
        left: auto;
        top: auto;
        height: auto;
    }

    .close {
        right: 10px;
        top: 10px;
    }
}

h1 {
    color: #fff;
}
