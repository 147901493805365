
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css');

:root {
    --width: 110px;
    --height: 50px; 
    --quantity: 8;
    --duration: 15s

}

.carousel-container {
    /* overflow-x: hidden; */
    height: 200px;
    padding: 50px 20px;
}

  .infinitecarousel {
    width: 100%;
    height: var(--height);
    overflow: hidden;
    mask-image: linear-gradient(to right, transparent, #000 10% 90%, transparent);
    margin: 20px 0;
  }
  .carousel:hover .carousel__item {
    animation-play-state: paused !important;
  }
  .carousel__list {
    display: flex;
    width: 100%;
    min-width: calc(var(--width) * var(--quantity));
    position: relative;
  }
  .carousel__item {
    width: var(--width);
    height: var(--height);
    position: absolute;
    left: 100%;
    text-align: center;
    font-size: 45px;
    animation: slide var(--duration) linear infinite;
    animation-delay: calc((var(--duration) / var(--quantity)) * (var(--position) - var(--quantity))) !important;
  }
  [data-reverse="true"] .carousel__item {
    animation: slideReverse var(--duration) linear infinite;
  }
  @keyframes slide {
    from {
      left: 100%;
    }
    to {
      left: calc(var(--width) * -1);
    }
  }
  @keyframes slideReverse {
    from {
      left: calc(var(--width) * -1);
    }
    to {
      left: 100%;
    }
  }
  