/* h1 {
    animation: move-text 7s forwards;
} */

.intro-bg {
    background: url('../../assets/intro-bg.gif');
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.text {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    border: 0;
    flex-direction: column;
    width: 60%;
    justify-content: center;
    margin: auto;

    font-family: 'Roboto';
    color: #fff;
}

/* h1{
    opacity: 0;
} */

#text h1 {
    opacity: 0;
    position: absolute;
    text-align: center;
    font-size: 50px;
    text-shadow: 1px 1px 4px #cbb8b8;
}

video {
    width: 100vw;
   height: 100vh;
   object-fit: cover;
   position: fixed;
   left: 0;
   right: 0;
   top: 0;
   bottom: 0;
   z-index: 0 !important;
}

.text h1:first-child {
    animation: zoom-in-zoom-out 6s 2s ease-in-out;
}

.text h1:nth-child(2) {
    animation: zoom-in-zoom-out 6s 9s ease-in-out;
}

.text h1:nth-child(3) {
    animation: zoom-in-zoom-out 6s 16s ease-in-out;
}

.text h1:nth-child(4) {
    animation: zoom-in-zoom-out 6s 24s ease-in-out;
}

@keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
      opacity: 0;
      display: block;
    }

    25% {
        /* transform: scale(1.25, 1.25); */
        opacity: 0.5; 
    }

    50% {
      /* transform: scale(1.5, 1.5); */
      opacity: 0.75;
    }

    100% {
      /* transform: scale(2, 2); */
      opacity: 1;
      display: none;
    }
  }

@media (max-width: 991px)  {
    #text h1 {
        font-size: 30px;
    }

    video {
        object-fit: fill;
    }

    .langVid {
        object-fit: none;
    }
}

.video-page {
    height: 100%;
}


.skip-btn-vid {
    position: absolute !important;
    bottom: 5%;
    right: 5%;
    padding: 20px;
}