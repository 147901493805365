/* html,
body {
  height: 100%;
}

body {
  background: black;
}



.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.countdown {
  display: block;
  width: 200px;
  height: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  font-size: 150px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: width, height, border-radius, font-size;
  transition-duration: 0.2s;
}
.countdown--ended {
  -webkit-animation: buzz 0.5s;
          animation: buzz 0.5s;
}
.countdown:active {
  transform: scale(1.02);
}
@keyframes buzz {
  0% {
    transform: rotate(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: rotate(6deg);
  }
  20%, 40%, 60%, 80% {
    transform: rotate(-6deg);
  }
  100% {
    transform: rotate(0);
  }
}

.countdown__fill {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #ff5722;
  opacity: 1;
}
.countdown__digit {
  width: 100%;
  color: #ff5722;
  text-align: center;
  mix-blend-mode: difference;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.countdown__buttons {
  position: absolute;
  right: 50px;
  top: 50%;
  height: 200px;
  margin-top: -100px;
  color: white;
  z-index: 1;
}
.countdown__button {
  height: 50%;
}

.full-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 1em 0.5em 0.5em 2em;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
} */


*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  background: #32386D;
  font-family: Helvetica, Arial, sans-serif;
}
.demo {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 500px;
  height: 140px;
  margin-top: -70px;
  padding: 10px;
  border-radius: 20px;
  transform: translateX(-50%);
}
.demo__colored-blocks {
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 0;
  width: 500px;
  height: 100%;
  margin-left: -250px;
  padding: 10px;
  border-radius: 20px;
  perspective: 1000px;
  animation: demoAnim 4s ease-in-out infinite, contAnim 4s infinite;
}
.demo__colored-blocks-rotater {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  animation: rotation 1.3s linear infinite;
}
.demo__colored-blocks-inner {
  overflow: hidden;
  position: relative;
  height: 100%;
  background: #32386D;
  border-radius: inherit;
}
.demo__colored-block {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 300%;
  height: 300%;
  transform-origin: 0 0;
}
.demo__colored-block:nth-child(1) {
  transform: rotate(0deg) skewX(-30deg);
  background-color: #FD3359;
}
.demo__colored-block:nth-child(2) {
  transform: rotate(120deg) skewX(-30deg);
  background-color: #F4D302;
}
.demo__colored-block:nth-child(3) {
  transform: rotate(240deg) skewX(-30deg);
  background-color: #21BDFF;
}
.demo__inner {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}
.demo__numbers {
  overflow: visible;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100px;
  height: 100px;
  margin-left: -50px;
  margin-top: -50px;
}
.demo__numbers-path {
  fill: none;
  stroke-width: 10px;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 0, 518.055065155;
  stroke-dashoffset: 0;
  animation: numAnim 4s ease-in-out infinite;
  opacity: 0;
}
.demo__text {
  position: absolute;
  left: 50%;
  top: 0;
  width: 500px;
  height: 100%;
  margin-left: -250px;
  text-align: center;
  line-height: 140px;
  font-size: 100px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 15px;
  transform: translateX(10px);
  animation: hideText 4s infinite;
}
@keyframes contAnim {
  15%, 100% {
    margin-left: -250px;
    width: 500px;
  }
  25%, 90% {
    margin-left: -70px;
    width: 140px;
  }
}
@keyframes numAnim {
  15% {
    stroke-dasharray: 0, 518.055065155;
    stroke-dashoffset: 0;
    opacity: 0;
  }
  25%, 41% {
    opacity: 1;
    stroke-dasharray: 144.4256591797, 518.055065155;
    stroke-dashoffset: -40;
  }
  53%, 66% {
    stroke-dasharray: 136.0216217041, 518.055065155;
    stroke-dashoffset: -227.238697052;
  }
  76% {
    stroke-dasharray: 113.4751205444, 518.055065155;
    stroke-dashoffset: -445.8995704651;
  }
  88%, 100% {
    stroke-dasharray: 72.1554946899, 518.055065155;
    stroke-dashoffset: -445.8995704651;
  }
  92% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes rotation {
  to {
    transform: rotate(360deg);
  }
}
@keyframes demoAnim {
  15% {
    border-radius: 20px;
    transform: rotate(0);
  }
  30%, 43% {
    border-radius: 50%;
    transform: rotate(360deg);
  }
  52%, 65% {
    border-radius: 0;
    transform: rotate(720deg);
  }
  78%, 90% {
    border-radius: 50%;
    transform: rotate(1080deg);
  }
  100% {
    border-radius: 20px;
    transform: rotate(1440deg);
  }
}
@keyframes hideText {
  15%, 100% {
    opacity: 1;
  }
  20%, 96% {
    opacity: 0;
  }
}
