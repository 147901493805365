.mascot-container {
    display: flex;
    margin: 0 20px;
}
.mascot-block{
    display: flex;
    align-items: center;
}

.mascot-img {
    width: 200px !important;
}

.mascot-content{
    padding: 10px;
    width: 600px;
}

.review.item {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    border: 1px solid;
    border-radius: 30px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* .review-img {
    width: 400px;
} */

.review.item h3 {
    text-align: center;
}

.owl-carousel .owl-stage, .owl-carousel .owl-item {
    display: flex;
}