/* Quiz page */

.quizPanel {
  min-width: 90%;
  height: 80VH;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: white;*/
  margin: auto;
  border-radius: 30px;
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
  /* background: white;
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));*/
  backdrop-filter: blur(1rem);
  box-shadow: 0 0 0 8px #28284b;
  /*background: #06061c;*/
  background: rgba(9, 9, 52);
  border: 1.54px solid;
  border-image-source: linear-gradient(158.28deg, rgba(255, 255, 255, 0.08) 3.81%, rgba(255, 255, 255, 0.04) 95.31%);


}

.quizPanel-left {
  width: 50%;
  height: 100%;
  position: relative;
  background-image: url('../../assets/quizleft.svg');
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.quizPanel-left .video {
  position: static;
}

.quizPanel-right {
  position: relative;
  width: 50%;
  height: 100%;
  padding: 2rem;
  overflow-y: auto;
}

.quizPanel-right::-webkit-bar {
  width: 12px;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: rgb(19, 27, 114);
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.quizPanel-right * {
  position: relative;
}

.quizPanel-right .question {
  margin: 20px auto;
  /* width: 60%;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 5px; */
}

.quizPanel-right .question h2 {
  margin-bottom: 10px;
  color: #eddfdf;
  font-size: 24px;
  font-weight: 300;
}

.options {
  list-style: none;
  padding: 0;
  /* display: grid;
    grid-template-columns: auto auto;
    gap: 10px; */
}

.options>li>button {
  margin-bottom: 10px;
  width: 100%;
  background-color: transparent;
  color: #fff;
  border: 2px solid #45429f;
  font-weight: 300;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.results {
  /* margin: 20px auto;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px; */
  color: #fff;
}

.results ul {
  list-style: none;
  padding: 0;
}

.results>ul>li>p {
  font-size: 20px;
}

.results>ul>li {
  border: 1px solid #ddd;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
}

.results>ul>li>p:first-child {
  font-size: 24px;
}


.quizPanel-text {
  margin: auto;
  color: rgba(192, 192, 192, 0.9);
  margin-top: 20px;
}

.quizPanel-text h1 {
  font-size: 40px;
  margin: 0;
}

.quizPanel-text h4 {
  font-size: 28px;
  margin: 10px 0 0;
}

.question-num {
  color: #5a5a91;
}

.quizPanel-section {
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow: hidden;
}

.sharkGif {
  width: 50%;
}

.quizPanel-logo {
  margin-left: auto;
}

.quizPanel-logo img {
  width: 100px;
}

@media (max-width : 990px) {
  .quizPanel {
    flex-direction: column;
    border-radius: 10px;
  }

  .quizPanel-left {
    justify-content: center;
    border-radius: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 150px;
    justify-content: center;
    width: 100%;
  }

  .quizPanel-right {
    width: 100%;
    border-radius: 0;
  }

  .quizPanel-text {
    margin: auto;
    display: flex;
    color: rgba(192, 192, 192, 0.9);
    flex-direction: column;
    margin-top: 0;
    display: none;
  }

  .quizPanel-section {
    display: flex;
    flex-direction: row-reverse;
    overflow: hidden;
    padding: 10px 10px 0;
    height: 100%;
    width: 100%;
  }

  .quizPanel-text h1 {
    font-size: 30px;
    margin: 0;
  }

  .sharkGifhidden {
    display: none;
  }

  .quizPanel-text h4 {
    font-size: 22px;
    margin: 10px 0 0;
  }

  .sharkGif {
    width: 150px;
    position: relative;
    margin-bottom: -10px;
  }
}


.options>li>.correct {
  background-color: rgba(40, 255, 100, 1);
  color: #fff;
  transition: all 1s ease-in;
}

.options>li>.wrong {
  background-color: #2499ed;
  color: #fff;
  transition: all 1s ease-in;
}

.question .button {
  border-radius: 5px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  transition: .3s;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #df4e4e;
  }
}

.question .button:hover,
.question .button:focus {
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.4);
  transform: scale(1.05, 1.05);
  animation: pulse 1s;
  box-shadow: 0 0 0 2em transparent;
}

.glow-on-hover:not(.correct):not(.wrong):before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
}

.glow-on-hover:not(.correct):not(.wrong):active:after {
  background: transparent;
}

.glow-on-hover:not(.correct):not(.wrong):hover:before {
  opacity: 1;
}

.glow-on-hover:not(.correct):not(.wrong):after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.card-glow-border {
  padding: 1px;
  border-radius: 10px;
  cursor: pointer;
  background: rgba(245, 245, 245, 0.2);
  position: relative;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.7);
}

.card-glow-border::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: radial-gradient(500px circle at var(--mouse-x) var(--mouse-y), rgba(255, 255, 255, 0.8), transparent);
  z-index: -1;
  top: 0;
  left: 0;
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.5s;
}

.card-glow-border::after {
  /* content: ''; */
  position: absolute;
  /* background: linear-gradient(to right bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)); */
  top: 0;
  left: 0;
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  margin: 5px;
  border-radius: 7px;
}

.card-glow-border:hover::before {
  opacity: 1;
}

.card-middle {
  background: rgb(20, 20, 20);
  padding: 5px;
  border-radius: 10px;
}

.card-content {
  height: 500px;
  width: 500px;
  border: 1px solid rgba(245, 245, 245, 0.2);
  border-radius: 5px;
  background-color: rgb(30, 30, 30);
  overflow: hidden;
  position: relative;
}

.card-content::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  background: radial-gradient(400px circle at var(--mouse-x) var(--mouse-y), rgba(245, 245, 245, 0.1), transparent);
  opacity: 0;
  transition: opacity 0.5s;

}

.card-glow-border:hover .card-content::before {
  opacity: 1;
}

.full-width {
  height: auto;
  width: 100%;
  margin: 20px 0;
}