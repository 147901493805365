/* :root {
    --rotate-x: 360deg;
    --rotate-y: 45deg;
  } */


.profilepicture {
    position: absolute; 
    width: 180px;
    height: 180px;
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/6083/profile/profile-512_1.jpg) no-repeat;
    background-size: 180px 180px;
    margin: auto; 
    top: 0; left: 0; bottom: 0; right: 0;
    z-index: 3;
  
    -webkit-box-shadow: 
      0 0 0 10px  rgba(255,255,255,.2),
      0 0 5px 2px rgba(0,0,0,.3),
inset 0 0 0 10px  rgba(0,0,255,.2);
    -moz-box-shadow: 
      0 0 0 10px  rgba(255,255,255,.2),
      0 0 5px 2px rgba(0,0,0,.3),
inset 0 0 0 10px  rgba(0,0,255,.2);
    box-shadow: 
      0 0 0 10px  rgba(255,255,255,.2),
      0 0 5px 2px rgba(0,0,0,.3),
inset 0 0 0 10px  rgba(0,0,255,.2);
  
    -webkit-border-radius: 999px; 
    -moz-border-radius: 999px;
    border-radius: 999px;
    /* border-radius: 50% has issues on some mobile browsers */
}

.pulse1 {
  position: absolute;
  width: 100px;
  height: 100px;
  margin: auto;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  opacity: 0;
  border: 3px solid rgba(255, 255, 255, .1);
  animation: pulsejg1 4s linear infinite;
  border-radius: 999px;
  box-shadow: inset 0px 0px 15px 10px rgba(255, 255, 255, 1);
  box-sizing: border-box;
  top: -50px;
}

.pulse2 {
  position: absolute;
  width: 300px;
  height: 300px;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  opacity: 0;
  border: 1px solid rgba(255, 255, 255, 0);
  animation: pulsejg2 4s linear infinite;
  border-radius: 999px;
  /* box-shadow: inset 0px 0px 12px 5px rgba(255, 255, 255, .8); */
  box-sizing: border-box;
}

@keyframes pulsejg1 {
    0% {
        transform: scale(.8);
        opacity: 0;
    }

    50% {
        transform: scale(1);
        opacity: 0;
    }

    60% {
        transform: scale(1.3);
        opacity: .1;
    }

    70% {
        transform: scale(1.5);
        opacity: .25;
    }

    80% {
        transform: scale(1.7);
        opacity: .1;
    }

    100% {
        transform: scale(2);
        opacity: 0;
    }
}

@keyframes pulsejg2 {
    0% {
        transform: scale(.1);
        opacity: 0;
    }

    40% {
        transform: scale(.9);
        opacity: .05;
    }

    50% {
        transform: scale(1.1);
        opacity: .1;
    }

    60% {
        transform: scale(1.3);
        opacity: .3;
    }

    80% {
        transform: scale(1.7);
        opacity: .1;
    }

    100% {
        transform: scale(2);
        opacity: 0;
    }
}
  
  * {
    box-sizing: border-box;
  }

  
  input[type=checkbox] {
    display: none;
  }
  
  #treasure-chest-positioner, #treasure-chest-positioner1, #treasure-chest-positioner2, #treasure-chest-positioner3, #treasure-chest-positioner4, #treasure-chest-positioner5, #treasure-chest-positioner6, #treasure-chest-positioner7, #treasure-chest {
    width: 10rem;
    height: 10rem;
  }
  
  #treasure-chest, #lid, #lid-panels, #chest, #front-panel, #left-panel, #right-panel, #back-panel {
    transform-style: preserve-3d;
  }
  
  /* #treasure-chest-positioner {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  } */
  
  #treasure-chest {
    transition: 0.2s;
    display: block;
    transform: translateZ(-5rem) rotateX(var(--rotate-x)) rotateY(var(--rotate-y));
  }
  #treasure-chest:hover {
    cursor: pointer;
  }
  
  #lid {
    position: absolute;
    top: 0;
    left: 0;
    width: 10rem;
    height: 3.3333333333rem;
    transition: transform 0.3s;
    transform-origin: 100% 45% -2.5rem;
  }
  #toggle-treasure:checked + #treasure-chest-positioner #lid,
  #toggle-treasure1:checked + #treasure-chest-positioner1 #lid,
  #toggle-treasure2:checked + #treasure-chest-positioner2 #lid,
  #toggle-treasure3:checked + #treasure-chest-positioner3 #lid,
  #toggle-treasure4:checked + #treasure-chest-positioner4 #lid,
  #toggle-treasure5:checked + #treasure-chest-positioner5 #lid,
  #toggle-treasure6:checked + #treasure-chest-positioner6 #lid,
  #toggle-treasure7:checked + #treasure-chest-positioner7 #lid    {
    transform: rotateX(110deg);
  }
  #lid #lid-panels {
    width: 9.8rem;
    height: 3.3333333333rem;
    transform: translateX(-4.7rem) translateY(-1.7rem);
  }
  #lid .panel {
    /* background-color: #D58757; */
    height: 66%;
    width: 100%;
    position: relative;
    transform-style: preserve-3d;
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/172299/wood.jpeg), linear-gradient(90deg, rgba(192,192,192,1) 0%, rgba(179,177,177,1) 35%, rgba(192,192,192,1) 100%);;
    backface-visibility: hidden;
    background-blend-mode: color-burn;
  }
  #lid .panel:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transform: rotateX(180deg);
    background-image: none;
    background-color: #553623;
    position: absolute;
    top: 0;
    left: 0;
    transform-style: preserve-3d;
    border: 1px solid black;
    background-image: linear-gradient(to bottom, transparent 49%, black 49%, black 51%, transparent 51%);
  }
  #lid .panel#panel-0 {
    transform: translateZ(2.8571428571rem) translateY(-0.4rem);
  }
  #lid .panel#panel-1 {
    transform: translateZ(6.5359477124rem) rotateX(45deg) translateY(-6.3291139241rem);
  }
  #lid .panel#panel-2 {
    transform: translateZ(0.2rem) translateY(-7.4074074074rem) rotateX(90deg);
  }
  #lid .panel#panel-3 {
    transform: translateZ(-10.5rem) rotateX(135deg) translateY(12.55rem);
  }
  #lid .panel#panel-4 {
    transform: translateZ(-2.4390243902rem) rotateX(180deg) translateY(9.1743119266rem);
  }
  #lid .panel .board {
    box-shadow: 0 0 0 1px black inset;
    height: 50%;
    width: 100%;
  }
  #lid .panel .iron-band {
    width: 1.1rem;
    height: 100%;
    border-left: 1px solid black;
    border-right: 1px solid black;
    position: absolute;
    top: 0;
    background-color: gray;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.6) 0%, transparent 15%, darkgray 20%, transparent 25%, transparent 85%, rgba(255, 255, 255, 0.4));
    transform-style: preserve-3d;
    transform: translateZ(1px);
  }
  #lid .panel .iron-band.left {
    left: 0;
  }
  #lid .panel .iron-band.middle {
    left: 50%;
    margin-left: -0.55rem;
  }
  #lid .panel .iron-band.right {
    right: -0.1rem;
  }
  
  #lid-left, #lid-right {
    width: 5.35rem;
    height: 3.75rem;
    position: absolute;
    overflow: hidden;
    transform-style: preserve-3d;
    -webkit-clip-path: polygon(30% 0%, 69% 0, 100% 45%, 100% 100%, 0 100%, 0 45%);
    clip-path: polygon(30% 0%, 69% 0, 100% 45%, 100% 100%, 0 100%, 0 45%);
    border-bottom: 1px solid black;
  }
  #lid-left .side-panel, #lid-right .side-panel {
    width: 5.25rem;
    height: 2.25rem;
    position: absolute;
    background: rgb(192,192,192);
    background: linear-gradient(90deg, rgba(192,192,192,1) 0%, rgba(179,177,177,1) 35%, rgba(192,192,192,1) 100%);;
    background-blend-mode: multiply;
    box-shadow: 0 0 0 1px black;
    bottom: 0;
    transform-style: preserve-3d;
    overflow: hidden;
  }
  #lid-left .side-panel:after, #lid-right .side-panel:after {
    content: "";
    display: block;
    position: absolute;
    width: 0.75rem;
    height: 100%;
    background: gray;
    transform-style: preserve-3d;
  }
  #lid-left .side-panel:first-child, #lid-right .side-panel:first-child {
    transform: rotateZ(43deg) translateY(2.1rem);
    z-index: 3;
  }
  #lid-left .side-panel:first-child:after, #lid-right .side-panel:first-child:after {
    transform: rotate(-45deg) translateX(0.75rem);
    top: 0;
    left: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.4);
  }
  #lid-left .side-panel:nth-child(2), #lid-right .side-panel:nth-child(2) {
    transform: rotateZ(75deg) translateY(1.65rem) translateX(-1.2rem);
    z-index: 2;
  }
  #lid-left .side-panel:nth-child(2):after, #lid-right .side-panel:nth-child(2):after {
    left: 1.4rem;
    top: -0.5rem;
    transform: rotate(-38deg);
    border-right: 1px solid rgba(0, 0, 0, 0.4);
  }
  #lid-left .side-panel:nth-child(3), #lid-right .side-panel:nth-child(3) {
    transform: rotateZ(90deg);
    z-index: 1;
  }
  #lid-left .side-panel:nth-child(3):after, #lid-right .side-panel:nth-child(3):after {
    border-right: 1px solid rgba(0, 0, 0, 0.4);
  }
  #lid-left .side-panel:nth-child(4), #lid-right .side-panel:nth-child(4) {
    transform: rotateZ(105deg) translateY(-1.3rem) translateX(-0.5rem);
    z-index: 2;
  }
  #lid-left .side-panel:nth-child(4):after, #lid-right .side-panel:nth-child(4):after {
    left: 0.75rem;
    top: 0.5rem;
    transform: rotate(30deg);
    border-right: 1px solid rgba(0, 0, 0, 0.4);
  }
  #lid-left .side-panel:last-child, #lid-right .side-panel:last-child {
    transform: rotateZ(137deg) translateY(-2rem);
    z-index: 3;
  }
  #lid-left .side-panel:last-child:after, #lid-right .side-panel:last-child:after {
    left: 0.55rem;
    top: 0.5rem;
    transform: rotate(43deg) translateZ(1rem);
    border-right: 1px solid rgba(0, 0, 0, 0.4);
  }
  
  #lid-left {
    transform: rotateY(90deg) translateX(-0.1rem) translateY(-2rem) translateZ(-2.3529411765rem);
  }
  
  #lid-right {
    transform: rotateY(90deg) translateX(-0.2rem) translateY(-5.3rem) translateZ(7.2463768116rem);
  }
  
  #chest {
    position: absolute;
    top: 0;
    left: 0;
    width: 10rem;
    height: 3.3333333333rem;
    transform-style: preserve-3d;
  }
  #chest #front-panel {
    width: 9.900990099rem;
    height: 4rem;
    position: absolute;
  }
  #chest #front-panel .side-chest-panel {
    background-blend-mode: color-burn;
  }
  #chest #back-panel {
    width: 9.900990099rem;
    height: 4rem;
    position: absolute;
  }
  #chest #back-panel .side-chest-panel {
    background-blend-mode: color-burn;
  }
  #chest #left-panel {
    width: 5.4054054054rem;
    height: 4rem;
    position: absolute;
  }
  #chest #left-panel .side-chest-panel {
    background-blend-mode: multiply;
  }
  #chest #right-panel {
    width: 5.4054054054rem;
    height: 4rem;
    position: absolute;
  }
  #chest #right-panel .side-chest-panel {
    background-blend-mode: multiply;
  }
  #chest .side-chest-panel {
    height: 25%;
    width: 100%;
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/172299/wood.jpeg), linear-gradient(90deg, rgba(192,192,192,1) 0%, rgba(179,177,177,1) 35%, rgba(192,192,192,1) 100%);
    border-bottom: 1px solid black;
    position: relative;
  }
  #chest .side-chest-panel:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 1px, transparent 1px), linear-gradient(to top, rgba(0, 0, 0, 0.3) 1px, transparent 1px), linear-gradient(to right, rgba(0, 0, 0, 0.5) 9%, transparent 11%, transparent 88%, rgba(0, 0, 0, 0.6) 91%);
  }
  #chest .side-chest-panel:nth-last-child(2):before {
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.3) 1px, transparent 1px), linear-gradient(to right, rgba(0, 0, 0, 0.2) 9%, transparent 11%, transparent 89%, rgba(0, 0, 0, 0.2) 91%), linear-gradient(to top, rgba(0, 0, 0, 0.5) 80%, transparent 95%), linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 1px, transparent 1px);
  }
  #chest #left-panel {
    transform: rotateY(90deg) translateZ(-2.4rem) translateY(1.8rem) translateX(-0.2rem);
  }
  #chest #left-panel .long .circle-cont {
    transform: translateY(-50%) rotateX(180deg) rotateZ(180deg);
  }
  #chest #left-panel .short .circle-cont {
    transform: translateX(-50%) rotateX(180deg) rotateZ(180deg);
  }
  #chest #bottom-panel {
    width: 9.900990099rem;
    height: 5.5555555556rem;
    position: absolute;
    transform: rotateX(-90deg) translateZ(3.1rem) translateX(0.1rem) translateY(-0.1rem);
  }
  #chest #bottom-panel .side-chest-panel {
    background-blend-mode: color-burn;
  }
  #chest #bottom-panel .side-chest-panel:before {
    background-image: none;
  }
  #chest #right-panel {
    transform: rotateY(90deg) translateZ(7.2rem) translateY(1.8rem) translateX(-0.2rem);
  }
  #chest #right-panel .iron-bars {
    transform: translateY(-0.75rem) translateZ(1.2rem);
  }
  #chest #back-panel {
    transform: translateZ(-2.5rem) translateY(1.8rem) translateX(0.1rem);
  }
  #chest #back-panel .iron-bars {
    transform: translateY(-0.75rem) translateZ(0.9rem);
  }
  #chest #back-panel .iron-bars .long .circle-cont {
    transform: translateY(-50%) rotateX(180deg) rotateZ(180deg);
  }
  #chest #back-panel .iron-bars .short .circle-cont {
    transform: translateX(-50%) rotateX(180deg) rotateZ(180deg);
  }
  #chest #front-panel {
    transform: translateZ(2.75rem) translateY(1.8rem) translateX(0.1rem);
  }
  
  .iron-bars {
    transform-style: preserve-3d;
    transform: translateY(-0.75rem) translateZ(1rem);
    position: relative;
  }
  
  .iron-bar {
    background: gray;
    position: absolute;
    transform: translateZ(-0.9rem);
    display: flex;
    transform-style: preserve-3d;
  }
  .iron-bar.short {
    width: 0.9rem;
    height: 4.2553191489rem;
    box-shadow: -1px 0 0 0 rgba(0, 0, 0, 0.5) inset;
    padding: 0.1rem 0;
    justify-content: space-between;
    flex-direction: column;
  }
  .iron-bar.short.middle-bar {
    left: 50%;
    margin-left: -0.45rem;
  }
  .iron-bar.short.right-bar {
    box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.5) inset;
  }
  .iron-bar.short .circle-cont {
    left: 50%;
    transform: translateX(-50%);
  }
  .iron-bar.long {
    height: 0.9rem;
    width: 100%;
    justify-content: space-around;
    padding: 0 1.1rem;
  }
  .iron-bar.long.top-bar {
    transform: translateZ(-1rem) translateY(-3.4482758621rem);
  }
  .iron-bar.long .circle-cont {
    top: 50%;
    transform: translateY(-50%);
  }
  #front-panel .iron-bar.short {
    transform: translateZ(-0.9rem) translateY(-3.4482758621rem) translateX(0.1rem);
  }
  #front-panel .iron-bar.short:last-child {
    transform: translateZ(-1rem) translateY(-3.6363636364rem) translateX(8.8888888889rem);
  }
  #back-panel .iron-bar.short {
    transform: translateZ(-1.05rem) translateY(-3.4482758621rem);
  }
  #back-panel .iron-bar.short:last-child {
    transform: translateZ(-1.05rem) translateY(-3.6363636364rem) translateX(8.8888888889rem);
  }
  #right-panel .iron-bar.long {
    transform: translateZ(-1.1rem) translateY(0);
  }
  #right-panel .iron-bar.long.top-bar {
    transform: translateZ(-1.1rem) translateY(-3.6363636364rem);
  }
  #right-panel .iron-bar.short {
    transform: translateZ(-1.1rem) translateY(-3.5714285714rem);
    box-shadow: 1px 0 0 0 rbga(black, 0.5) inset;
  }
  #right-panel .iron-bar.short:last-child {
    transform: translateZ(-1.1rem) translateY(-3.5714285714rem) translateX(4.5454545455rem);
  }
  #left-panel .iron-bar.long {
    transform: translateZ(-1.1rem) translateY(0);
  }
  #left-panel .iron-bar.long.top-bar {
    transform: translateZ(-1.05rem) translateY(-3.6363636364rem);
  }
  #left-panel .iron-bar.short {
    transform: translateZ(-1.15rem) translateY(-3.5714285714rem);
    box-shadow: 1px 0 0 0 rbga(black, 0.5) inset;
  }
  #left-panel .iron-bar.short:last-child {
    transform: translateZ(-1.15rem) translateY(-3.5714285714rem) translateX(4.5454545455rem);
  }
  .iron-bar .circle-cont {
    width: 0.8rem;
    height: 0.8rem;
    position: relative;
    transform-style: preserve-3d;
  }
  
  .circle {
    background-color: gray;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -0.325rem 0 0 -0.325rem;
    background-image: radial-gradient(circle at 30% 30%, white 5%, transparent 35%), radial-gradient(circle at center, transparent 50%, rgba(0, 0, 0, 0.65) 80%);
    backface-visibility: hidden;
  }
  .circle:nth-child(2) {
    transform: translateZ(1px);
  }
  .circle:nth-child(3) {
    transform: translateZ(1.5px);
  }
  .circle:nth-child(4) {
    transform: translateZ(2px);
  }
  .circle:nth-child(5) {
    transform: translateZ(2.5px);
  }
  .circle:nth-child(6) {
    transform: translateZ(3px);
  }
  .circle:nth-child(7) {
    transform: translateZ(3.5px);
  }
  .circle:nth-child(8) {
    transform: translateZ(4px);
  }
  .circle:nth-child(9) {
    transform: translateZ(4.5px);
  }
  .circle:nth-child(1) {
    width: 0.65rem;
    height: 0.65rem;
  }
  .circle:nth-child(2) {
    width: 0.65rem;
    height: 0.65rem;
  }
  .circle:nth-child(3) {
    width: 0.65rem;
    height: 0.65rem;
  }
  .circle:nth-child(5) {
    width: 0.6rem;
    height: 0.6rem;
    background-image: radial-gradient(circle at 30% 30%, white 5%, transparent 35%), radial-gradient(circle at center, transparent 50%, rgba(0, 0, 0, 0.5) 80%);
    margin: -0.3rem 0 0 -0.3rem;
  }
  .circle:nth-child(6) {
    width: 0.5rem;
    height: 0.5rem;
    background-image: radial-gradient(circle at 30% 30%, white 5%, transparent 35%), radial-gradient(circle at center, transparent 50%, rgba(0, 0, 0, 0.3) 80%);
    margin: -0.25rem 0 0 -0.25rem;
  }
  .circle:nth-child(7) {
    width: 0.4rem;
    height: 0.4rem;
    background-image: radial-gradient(circle at 30% 30%, white 5%, transparent 35%), radial-gradient(circle at center, transparent 50%, rgba(0, 0, 0, 0.3) 80%);
    margin: -0.2rem 0 0 -0.2rem;
  }
  .circle:nth-child(8) {
    width: 0.2rem;
    height: 0.2rem;
    background-image: radial-gradient(circle at 30% 30%, white 5%, transparent 35%), radial-gradient(circle at center, transparent 50%, rgba(0, 0, 0, 0.3) 80%);
    margin: -0.1rem 0 0 -0.1rem;
  }
  
  #lock {
    width: 2rem;
    height: 1.5rem;
    background: gray;
    top: 50%;
    left: 50%;
    margin: -0.75rem 0 0 -1.05rem;
    position: absolute;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.3) inset;
  }
  #lock #keyhole {
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-bottom: 10px solid black;
    left: 50%;
    top: 50%;
    position: absolute;
    margin: -4px 0 0 -5px;
    width: 0.55em;
  }
  #lock #keyhole:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 50%;
    width: 7px;
    height: 7px;
    background-color: black;
    top: -4px;
    left: -3px;
  }
  
  #sliders {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
    background-color: black;
    font-family: sans-serif;
    font-size: 0.9rem;
    letter-spacing: 1px;
    color: white;
  }
  #sliders #slider-cont {
    position: relative;
    margin: 1.5rem 2.5rem;
  }
  @media (max-width: 800px) {
    #sliders {
      width: 100%;
      height: 20vh;
    }
  }
  #sliders span:first-child {
    text-transform: uppercase;
    color: silver;
    margin-right: 4px;
  }
  #sliders input {
    margin-top: 0.65rem;
    display: block;
  }
  #sliders input:nth-child(2) {
    margin-bottom: 1.25rem;
  }

  .coupon {
    position: absolute;
    width: 130px;
    right: 10px;
    top: 0;
    opacity: 0;
    transition: opacity 2s ease-in-out;
    transform: rotateX(var(--rotate-x)) rotateY(var(--rotate-y))
  }

  .tressure-container .coupon {
    top: -86px;
    opacity: 1;
    z-index: 1;
  }
  

  .golden #lid .panel {
    /* background-color: #D58757; */
    height: 66%;
    width: 100%;
    position: relative;
    transform-style: preserve-3d;
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/172299/wood.jpeg), linear-gradient(90deg, rgba(255,215,0,1) 0%, rgba(255,238,149,1) 35%, rgba(255,215,0,1) 100%);
    backface-visibility: hidden;
    background-blend-mode: color-burn;
  } 

  .golden #lid-left .side-panel{
    width: 5.25rem;
    height: 2.25rem;
    position: absolute;
    background: rgb(255,215,0);
background: linear-gradient(90deg, rgba(255,215,0,1) 0%, rgba(255,238,149,1) 35%, rgba(255,215,0,1) 100%);
    background-blend-mode: multiply;
    box-shadow: 0 0 0 1px black;
    bottom: 0;
    transform-style: preserve-3d;
    overflow: hidden;
  }
  
  .golden #chest .side-chest-panel {
    height: 25%;
    width: 100%;
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/172299/wood.jpeg), linear-gradient(90deg, rgba(255,215,0,1) 0%, rgba(255,238,149,1) 35%, rgba(255,215,0,1) 100%);
    border-bottom: 1px solid black;
    position: relative;
  }

  .golden #lid-left .side-panel, .golden #lid-right .side-panel {
    width: 5.25rem;
    height: 2.25rem;
    position: absolute;
    background: rgb(255,215,0);
background: linear-gradient(90deg, rgba(255,215,0,1) 0%, rgba(255,238,149,1) 35%, rgba(255,215,0,1) 100%);
    background-blend-mode: multiply;
    box-shadow: 0 0 0 1px black;
    bottom: 0;
    transform-style: preserve-3d;
    overflow: hidden;
  }