/* #root,
.App {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: auto;
} */

.lang-page{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lang-selector {
  /* position: absolute;
  top: 20%;
  left: 70%; */
  border-radius: 15px;
  background: white;
  background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
  backdrop-filter: blur(1rem);
  width: 300px;
  min-height: 300px;
  z-index: 1;
  display: flex;
  /* border-radius: 5rem; */
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  flex-direction: column;
  padding: 5px;
  color: #fff;
  opacity: 0;
  margin: auto;
}

.card-glow-border-inner{
  background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.1));
  padding: 15px;
  border-radius: 10px;
}

.lang-selector * {
  position: relative;
}

.button-container {
  display: flex;
  flex-direction: column;
}

.button-container>.button {
  margin: 10px;
  padding: 15px 20px;
  background: transparent;
  border-color: #fff;
  border-width: 1px;
  color: #fff;
  border-style: solid;
}

@media (orientation: portrait) {
  .lang-selector {
    top: 50%;
    left: 50%;
    width: 70%;
    transform: translate(-50%, -50%) !important;
    display: block;
    position: absolute;
  }
}

img.logo {
  position: absolute;
  width: 100px;
  top: 5%;
  left: 5%;
}

.rotate-screen {
  display: none;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
  -webkit-backdrop-filter: blur(1rem);
  backdrop-filter: blur(1rem);
  color: #fff;
}

@media (max-height : 700px) and (orientation: landscape) {
  /* .lang-selector {
    display: none;
  }

  .rotate-screen {
    display: flex;
    position: absolute;
    top: 0;
  } */

  .shark-talk {
    display: none;
  }
}

.shark-talk{
  /* position: static; */
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  
  animation-delay: 3s;
  /* background: #000; */
  background: white;
  background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
  backdrop-filter: blur(1rem);
}

.shark-animation {
  animation: move-shark 1s forwards;
}

.lang-animation {
  /* animation: move-lang 1s forwards;
  animation-delay: 1s; */
}

@keyframes move-shark {
  5% {
    opacity: 0.9;
    right: 0%;
  }

  10% {
    opacity: 0.8;
    right: 5%;
  }

  20% {
    opacity: 0.7;
    right: 10%;
  }
  
  30% {
    opacity: 0.6;
    right: 15%;
  }

  40% {
    opacity: 0.5;
    right: 20%;
  }

  50% {
    opacity: 0.4;
    right: 25%;
  }

  100% {
    opacity: 0;
    right: 100%;
  }
}

@keyframes move-lang {
  10% {
    opacity: 0.1;
    left: 100%;
  }

  50% {
    opacity: 0.4;
    left: 70%;
  }

  100% {
    opacity: 1;
    left: 60%;
  }
}

img.shark {
  position: absolute;
  bottom: 0;
  z-index: 1;
  /* mix-blend-mode: screen; */
  /* background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)); */
  /* opacity: 0.8; */
  height: 70%;
}

@font-face {
  font-family: 'SequentialistBB';
  src: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/306FA6_1_0.woff2') format('woff2'),
    url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/306FA6_0_0.woff') format('woff');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'SequentialistBB';
  src: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/306FA6_0_0.woff2') format('woff2'),
    url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/306FA6_0_0.woff') format('woff');
  font-style: italic;
  font-weight: 400;
}
/* 
* {
  box-sizing: border-box;
} */

body {
  font-family: SequentialistBB, cursive;
  /* font-size: 2.5vw; */
  margin: 0;
}

section {
    position: absolute;
    width: 47%;
    height: 400px;
    left: 200px;
    /* top: 100px; */
    color: #205a88;
    /* height: 94%; */
}

section {
  padding: 3rem;
}

blockquote.bubble {
  background-position: center;
  background-repeat: no-repeat !important;
  background-size: 100% 100%;
  margin: 0 auto;
  text-align: center;
  height: 0;
  box-sizing: content-box;
  line-height: 1;
  /* opacity: 0; */
}

blockquote.speech {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/speech-bubble.svg);
  width: 100%;
  padding-top: 10%;
    padding-bottom: 15%;
    height: 70%;
    padding-left: 15%;
    padding-right: 15%;
    font-size: 24px;
}

blockquote.speech > span{
  min-height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}



.skip-btn{
  /* position: absolute;
  bottom: 0;
  right: 0; */
  margin-right: 20px;
  
  background: transparent;
  color: #205a88;
  
  font-size: 14px;
  padding: 5px 10px;
  /* line-height: 16px; */
  height: 25px;
}



.start-btn{
  /* position: absolute;
  bottom: 0;
  right: 0; */
  margin-right: 20px;
  background: transparent;
  color: #205a88;
  font-size: 14px;
  padding: 5px 10px;
  /* line-height: 16px; */
  height: 25px;
  text-decoration: unset;
  font-family: arial;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.skip-btn:hover, .start-btn:hover {
  background: #0d99ff;
  color: #fff;
}

.video {
  position: absolute;
  bottom: 0;
  z-index: 1;
  /* mix-blend-mode: screen;
  background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
  opacity: 0.8; */
  height: 70%;
  right: 26%;
}

#video-container {
  height: 100%;
  width: 100vw;
  background-image: url("../../assets/IdleAlphaWEBM.webm");
}

video {
  height: 100%;
}

video {
  position: absolute;
  z-index: -1;
  height: 100%;
}

#video-container {
  height: 100%;
  width: 100vw;
  background-image: url("../../assets/IdleAlphaWEBM.mp4");
  background-color: transparent;
  position: absolute;
  z-index: 1;
}

.bubble-buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

@media (max-width : 990px) {
  section {
    width: 100%;
    position: relative;
    left: -1%;
    height: 300px;
    /* bottom: 0%; */
    bottom: 45px;
  }

  /* img.shark {
    height: 400px;
  } */

  blockquote.speech {
    height: 110%;
    font-size: 18px;
    padding-top: 12%;
    padding-bottom: 15%;
    padding-left: 10%;
    padding-right: 10%;
  }

  img.shark {
    width: 80%;
    right: 35%;
  }

  .video {
    position: absolute;
    bottom: 0;
    z-index: 1;
    height: 45%;
    right: 20%;
  }
}


.glow-on-hover:not(.correct):not(.wrong):before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 12px);
  height: calc(100% + 12px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
}

.glow-on-hover:not(.correct):not(.wrong):active:after {
  background: transparent;
}

.glow-on-hover:not(.correct):not(.wrong):hover:before {
  opacity: 1;
}

.glow-on-hover:not(.correct):not(.wrong):after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
}

.shadow {
  display: none;
}

.lang-selector.glow-on-hover:not(.correct):not(.wrong):after {
  background: #111;
}
.lang-selector.glow-on-hover:not(.correct):not(.wrong):active:after {
  background: #111;
}
.lang-selector.glow-on-hover:not(.correct):not(.wrong):before {
  width: calc(100% + 12px);
  height: calc(100% + 12px);
}

.mute {
  position: fixed;
    bottom: 0;
    right: 0;
    height: 50px;
    width: 50px;
    background: #fff;
    border-radius: 50%;
    margin: 10px;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}
