

/* Quiz page */

.quizPanel {
    min-width: 90%;
    height: 80VH;
    display: flex;
    align-items: center;
    justify-content: center;
   /* background: white;*/
    margin: auto;
    border-radius: 30px;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
    /*background: white;
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    backdrop-filter: blur(1rem);*/
    box-shadow: 0 0 0 8px #28284b;
   /* background: linear-gradient(148.08deg, #151225 15.1%, #05051E 81.94%);*/
   border: 1.54px solid;
border-image-source: linear-gradient(158.28deg, rgba(255, 255, 255, 0.08) 3.81%, rgba(255, 255, 255, 0.04) 95.31%);

}

.quizPanel-left {
  width: 50%;
  height: 100%;
  position: relative;
  background-image: url('../../assets/quizleft.svg');
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
}

.quizPanel-left .video {
  position: static;
}

.quizPanel-right {
  position: relative;
  width: 50%;
  height: 100%;
  padding: 2rem;
  overflow-y: auto;
}
.quizPanel-right::-webkit-scrollbar
{
	width: 12px;
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: rgb(19, 27, 114);
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.quizPanel-right * {
    position: relative;
}

.quizPanel-right .question {
    margin: 20px auto;
    /* width: 60%;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 5px; */
}

.quizPanel-right .question h2 {
    margin-bottom: 10px;
    color: #eddfdf;
    font-size: 24px;
    font-weight: 300;
}

.options {
    list-style: none;
    padding: 0;
    /* display: grid;
    grid-template-columns: auto auto;
    gap: 10px; */
}

.options > li > button {
    margin-bottom: 10px;
    width: 100%;
    background-color: transparent;
    color: #fff;
    border: 1px solid #45429f;
    font-weight: 300;
}

button {
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.results {
    /* margin: 20px auto;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px; */
    color: #fff;
}

.results ul {
    list-style: none;
    padding: 0;
}

.results > ul > li > p {
    font-size: 20px;
}

.results > ul > li {
    border: 1px solid #ddd;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
}

.results > ul > li > p:first-child {
    font-size: 24px;
}


.quizPanel-text {
  margin: auto;
  color: rgba(192, 192, 192, 0.9);
  margin-top: 20px;
}

.quizPanel-text h1 {
  font-size: 40px;
  margin: 0;
}

.quizPanel-text h4 {
  font-size: 28px;
  margin: 10px 0 0;
}

.question-num {
  color: rgba(145, 196, 255, 1);
}

.quizPanel-section{
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow: hidden;
}


.timer-block {
  flex: 1;
  flex-direction: row-reverse;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.sharkGif {
  width: 50%;
  flex: 1;
  height: 100%;
}
.quizPanel-logo{
  margin-left: auto;
}

.quizPanel-logo img{
  width: 100px;
}

@media (max-width : 990px) {
  .quizPanel{
    flex-direction: column;
    border-radius: 10px;
  }

  .quizPanel-left {
    justify-content: center;
    border-radius: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 150px;
    justify-content: center;
    width: 100%;
  }

  .quizPanel-right {
    width: 100%;
    border-radius: 0;
  }

  .quizPanel-text {
    margin: auto;
    display: flex;
    color: rgba(192, 192, 192, 0.9);
    flex-direction: column;
    margin-top: 0;
    display: none;
  }

  .quizPanel-section{
    display: flex;
    flex-direction: row-reverse;
    overflow: hidden;
    padding: 10px 10px 0;
    height: 100%;
    width: 100%;
  }

  .quizPanel-text h1 {
    font-size: 30px;
    margin: 0;
  }

  .sharkGifhidden{
    display: none;
  }

  .quizPanel-text h4 {
    font-size: 22px;
    margin: 10px 0 0;
  }

  .sharkGif {
    width: 150px;
    position: relative;
    margin-bottom: -10px;
  }
}

.options > li > .correct {
  background-color: rgba(40, 255, 100, 1);
  color: #fff;
  transition: all 1s ease-in;
}

.options > li > .wrong {
  background-color: #df4e4e;
  color: #fff;
  transition: all 1s ease-in;
}

.question .button {
  border-radius: 5px;
}
@keyframes pulse {
  0% { box-shadow: 0 0 0 0 #2499ed; }
}
.question .button:hover, .question .button:focus {
  box-shadow: 0px 20px 40px rgba(0,0,0,0.4);
  transform: scale(1.05,1.05);
  animation: pulse 1s;
  box-shadow: 0 0 0 2em transparent;
}
.glow-on-hover:not(.correct):not(.wrong):before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
}

.glow-on-hover:not(.correct):not(.wrong):active:after {
  background: transparent;
}

.glow-on-hover:not(.correct):not(.wrong):hover:before {
  opacity: 1;
}

.glow-on-hover:not(.correct):not(.wrong):after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}



html,
body {
  height: 100%;
}

body {
  background: black;
}



.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.countdown {
  display: block;
  width: 200px;
  height: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  font-size: 150px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: width, height, border-radius, font-size;
  transition-duration: 0.2s;
}
.countdown--ended {
  -webkit-animation: buzz 0.5s;
          animation: buzz 0.5s;
}
.countdown:active {
  transform: scale(1.02);
}
@keyframes buzz {
  0% {
    transform: rotate(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: rotate(6deg);
  }
  20%, 40%, 60%, 80% {
    transform: rotate(-6deg);
  }
  100% {
    transform: rotate(0);
  }
}

.countdown__fill {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #ff5722;
  opacity: 1;
  height: calc(var(--timerPer));
}
.countdown__digit {
  width: 100%;
  color: #ff5722;
  text-align: center;
  mix-blend-mode: difference;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.countdown__buttons {
  position: absolute;
  right: 50px;
  top: 50%;
  height: 200px;
  margin-top: -100px;
  color: white;
  z-index: 1;
}
.countdown__button {
  height: 50%;
}

.full-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 1em 0.5em 0.5em 2em;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
}

.silverQuiz-shark{
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.silverQuiz-shark-img-container {
  border-radius: 50%;
    border: 1px solid #fff;
    /* margin: 20px 10px; */
    margin: 30px 30px 30px 0;
    box-shadow: 0px 0px 24px 5px #fff;
    background: #fff;
    display: flex;
    width: 200px;
    height: 200px;
    overflow: hidden;
}

@media (max-width: 991px) {
  .silverQuiz-shark-img-container {
    width: 125px;
    height: 125px;
  }
}