.js-transitions-disabled * {
    transition: none !important;
}

.carousel {
    position: relative;
    height: 100%;
    /* overflow: hidden; */
    perspective: 50vw;
    perspective-origin: 50% 50%;
    box-shadow: inset 0 0 20px 8px #f3f3ec;
}

.carousel__control {
    position: absolute;
    height: 160px;
    width: 40px;
    background: #fff;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
}

.carousel__control a {
    position: relative;
    display: block;
    width: 100%;
    padding-top: 75%;
    box-sizing: border-box;
}

.carousel__control a:hover:before {
    background-color: rgba(0, 0, 0, 0.4);
}

.carousel__control a.active:before,
.carousel__control a.active:hover:before {
    background-color: rgba(0, 0, 0, 0.6);
}

.carousel__control a:first-child {
    margin-top: 15px;
}

.carousel__control a:before {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 50%;
    padding-top: 25%;
    width: 25%;
    background: rgba(0, 0, 0, 0.2);
    content: '';
    display: block;
    margin-top: -12.5%;
}

.carousel__stage {
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
    margin: auto;
    transform-style: preserve-3d;
    transform: translateZ(calc(-50vh + 20px));
}

.spinner {
    position: absolute;
    width: calc(50vw - (0px));
    height: calc(100vh - 20px);
    top: 0;
    left: 0;
    right: auto;
    bottom: 0;
    margin: auto;
    transform-style: preserve-3d;
    transition: transform 1s;
    backface-visibility: hidden;
    transform-origin: 50% 50%;
    transform: rotateX(0);
    /* box-shadow: 0 0 3px 5px white; */
}

.js-spin-fwd .spinner {
    transform: rotateX(-90deg);
}

.js-spin-bwd .spinner {
    transform: rotateX(90deg);
}

.js-spin-fwd .spinner--right {
    transform: rotateX(90deg);
}

.js-spin-bwd .spinner--right {
    transform: rotateX(-90deg);
}

.spinner--right {
    right: 0;
    left: auto;
}

.spinner__face {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.spinner__face.js-next {
    display: block;
    transform: rotateX(90deg) translateZ(calc(50vh - 20px));
}

.spinner--right .spinner__face.js-next {
    transform: rotateX(270deg) translateZ(calc(50vh - 20px));
}

.js-spin-bwd .spinner__face.js-next {
    transform: rotateX(-90deg) translateZ(calc(50vh - 20px));
}

.js-spin-bwd .spinner--right .spinner__face.js-next {
    transform: rotateX(-270deg) translateZ(calc(50vh - 20px));
}

.js-active {
    display: block;
    transform: translateZ(calc(50vh - 20px));
}

.content {
    position: absolute;
    width: 200%;
    height: 100%;
    left: 0;
}

.spinner--right .content {
    left: -100%;
}

.content__left,
.content__right {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
}

.content__right {
    right: 0;
    left: auto;
}

.content__left {
    background-repeat: no-repeat;
    background-size: cover;
}

.content__left:after {
    position: absolute;
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
}

.content__left h1 {
    margin-top: 20px !important;
    position: absolute;
    top: 85%;
    margin-top: 0vw;
    text-align: center;
    font-family: oswald;
    font-size: 5vw;
    height: 500px;
    opacity: 1;
    color: #fff;
    width: 100%;
    letter-spacing: 0.15em;
    line-height: 0.6;
    padding-top: 5px;
}

.content__left span {
    font-size: 1vw;
    font-weight: 300;
    letter-spacing: 0.2em;
    opacity: 0.9;
    font-family: Merriweather;
}

.content__right {
    display: flex;
    align-items: center;
    justify-content: center;
}

.content__right .content__main {
    position: absolute;
    font-family: Merriweather, serif;
    text-align: left;
    color: #fff;
    font-size: 1.3vw;
    padding: 0 8vw;
    line-height: 1.65;
    font-weight: 300;
    margin: 0;
    opacity: 0.8;
}

.content__right .content__main p:last-child {
    text-transform: uppercase;
    letter-spacing: 0.15em;
    font-size: 0.85em;
}

.content__right .content__index {
    font-size: 30vh;
    position: absolute;
    right: -1vh;
    top: 35vh;
    opacity: 0.04;
    font-family: oswald;
    color: #fff;
    z-index: -1;
}

[data-type="iceland"] .content__left {
    background-image: url("../../assets/Cricket-BG.jpg");
    background-position: center;
}

.spinner--right [data-type="iceland"] .content__left {
    background-image: none;
}

[data-type="china"] .content__left {
    background-image: url("../../assets/king.jpg");
    background-position: center;
}
[data-type="china"] .content__left h1 {
    color: #000;
    font-size: 30px;
}

.spinner--right [data-type="china"] .content__left {
    background-image: none;
}

[data-type="usa"] .content__left {
    background-image: url("../../assets/Travel-BG.jpg");
    background-position: center;
}

[data-type="usa"] .content__left h1 {
    color: rosybrown;
    font-size: 38px;
}

.spinner--right [data-type="usa"] .content__left {
    background-image: none;
}

[data-type="peru"] .content__left {
    background-image: url("../../assets/foodieBG.png");
    background-position: center;
}

[data-type="peru"] .content__left h1 {
    font-size: 35px;
}

.spinner--right [data-type="peru"] .content__left {
    background-image: none;
}

[data-type="india"] .content__left {
    background-image: url("../../assets/finance.png");
    background-position: center;
}

[data-type="india"] .content__left h1 {
    font-size: 35px;
}

.spinner--right [data-type="india"] .content__left {
    background-image: none;
}

[data-type="canada"] .content__left {
    background-image: url("../../assets/parallex/Sage.png");
    background-position: center;
}

[data-type="canada"] .content__left h1 {
    color: #ffbf01;
}

.spinner--right [data-type="canada"] .content__left {
    background-image: none;
}

.pulse-btn-car {
    
}

.carBtn-grp {
    display: flex;
    justify-content: space-between;
}

.carQuizBtn{
    background: #fff;
    color: #0d99ff;
    width: auto;
    height: 50px;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    /* width: 100px; */
    margin-top: 30px;
    animation: pulseWhite 1s infinite;
    box-shadow: 0 20px 40px #0006;
    box-shadow: 0 0 0 2em #0000;
    transform: scale(1.05);
}

.carBtn {
    background: #0d99ff;
    width: auto;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    /* width: 100px; */
    margin-top: 30px;
    animation: pulse 1s infinite;
    box-shadow: 0 20px 40px #0006;
    box-shadow: 0 0 0 2em #0000;
    transform: scale(1.05);
}

@keyframes pulseWhite {
    0% {
      box-shadow: 0 0 0 0 #fff;
    }
  }

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #3289ef;
  }
}

@media (max-width: 991px) {
    .content__right .content__main {
        font-size: 13px;
    }

    .carBtn-grp {
        flex-direction: column;
    }

    .spinner_shark{
        height: 55%;
    }

    [data-type="canada"] .content__left {
        background-image: url("../../assets/Untitled-100.jpg");
        background-position: 66% 0;
    }

    [data-type="iceland"] .content__left {
        background-image: url("../../assets/Cricket-BG-01.jpg");
        background-position: center;
    }

    [data-type="peru"] .content__left {
        background-image: url("../../assets/Food-BG.01.jpg");
        background-position: center;
    }

    [data-type="usa"] .content__left {
        background-image: url("../../assets/Travel-BG.01.jpg");
        background-position: center;
    }
    [data-type="india"] .content__left {
        background-image: url("../../assets/Finance\ Mobile.png");
        background-position: center;
    }
    [data-type="china"] .content__left {
        background-image: url("../../assets/Untitled-1000.jpg");
    }
}
