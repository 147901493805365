.loader-container {
        DISPLAY: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
}

.loader-container img {
    width: 100px;
    height: 100px;
}

