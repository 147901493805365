.page {
    height: 100%;
    width: 100%;
  }
  
  /* .page1 {
    background-color: crimson;
  }
  
  .page2 {
    background-color: red;
  } */
  
  .box {
    width: 300px;
    height: 300px;
    background-color: white;
  }

  html, body, #root, .App{
    /* height: 100%; */
    width: 100%;
    background-color: #13568a;
  }

  
#page1 {
    background-image: url('../../assets/parallex/bg/Layer1\ 2.jpg');
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

#page2 {
    background-image: url('../../assets/parallex/bg/Layer-2\ 3.jpg');
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    overflow: hidden;
    position: relative;
    flex-direction: column;
}

/* #page2:after {
  content: "";
  background-image: url("../../assets/parallex/01.png");
  height: 100%;
  width: 100%;
  position: absolute;
  right: -85px;
  background-size: cover;
  z-index: -1;
  background-position: right bottom;
} */

/* #page4:after {
  content: "";
  background-image: url("../../assets/parallex/27.png");
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  background-size: cover;
  z-index: -1;
  background-position: right bottom;
} */

/* #page4:before {
  content: "";
  background-image: url("../../assets/parallex/03.png");
  height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-size: cover;
    z-index: 1;
} */

.box{
    background-color: red;
    height: 200px;
    width: 200px;
}

#page3 {
    background-image: url('../../assets/parallex/bg/Layer-2\ 3.jpg');
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    overflow: hidden;
    position: relative;
    flex-direction: column;
    background-color: #071e42;
}

/* #page3:after {
  content: "";
  background-image: url("../../assets/parallex/03.png");
  height: 100%;
  width: 100%;
  position: absolute;
  left: -85px;
  background-size: cover;
  z-index: -1;
  background-position: left bottom;
}

#page3:before {
  content: "";
  background-image: url("../../assets/parallex/02.png");
} */

#page4, #page6 {
    background-image: url('../../assets/parallex/bg/Layer-2\ 3.jpg');
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: center;
    flex-direction: column;
    background-color: #071e42;
}

.moon-img {
    position: absolute;
    top: 41% !important;
    left: 88%;
    transform: translate(-50%, -50%);
    width: 150px !important;
    height: 150px !important;
    background-image: url('../../assets/parallex/moon.png');
    background-size: contain;
}

.sea-img {
    position: absolute;
    bottom: 0;
    /* left: 88%; */
    /* transform: translate(-50%, -50%); */
    width: 100% !important;
    height: 310px !important;
    background-image: url('../../assets/parallex/sea.png');
    background-size: cover;
    background-position: bottom;
}


  
.page2 h1, .page3 h1, .page4 h1 {
    font-size: 30px;
    font-family: "roboto";
    margin: 10% 20%;
}

.page6 h1 {
    margin: 0;
}

img.sharkImg {
    /* transform: translate(-1000px, -1000px); */
    height: 200px;
    position: absolute;
    top: 25%;
}

img.scuba-driver {
    position: absolute;
    height: 200px;
    /* transform: rotate(98deg);
    transform: skewX(-16deg) rotateZ(-40deg) rotateY(54deg); */
    transform: scale(1, -1) rotate(150deg);
    /* height: 200px; */
    width: 200px;
    left: 24px;
    top: 60%;
    margin-left: -285px;
    /* z-index: 2; */
    animation: swim-scuba 20s;
      animation-iteration-count: infinite;
      -webkit-animation-iteration-count: infinite;
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      animation-delay: 3s;
      z-index: -1;
}

.page1-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 4;
}

.page1-content h1, .page1-content h3 {
    text-align: center;
    margin: 0;
}



.page1-content .page-logo {
    width: 400px;
    text-align: center;
}

.parallexPage {
    background-image: url("../../assets/parallex/parallex-bg.jpg");
    height: 100%;
    width: 100%;
    background-size: cover;
}

  img.coral-close,img.coral {
    width: 100px;
    height: 50px;
}

.tressure {
    flex-direction: row;
    display: flex;
    align-items: center;
}
  
  
  .bubble {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    position: absolute;
    background-color: white;
    bottom: -30px;
    opacity: 0.2;
    animation: bubble 15s ease-in-out infinite,
      sideWays 4s ease-in-out infinite alternate;
  }
  
  @keyframes bubble {
    0% {
      transform: translateY(0%);
      opacity: 0.06;
    }
    100% {
      transform: translateY(-320vh);
    }
  }
  
  @keyframes sideWays {
    0% {
      margin-left: 0px;
    }
    100% {
      margin-left: 200px;
    }
  }
  
  .bubble--1 {
    left: 10%;
    animation-delay: 0.5s;
    animation-duration: 16s;
    opacity: 0.2;
  }
  
  .bubble--2 {
    width: 15px;
    height: 15px;
    left: 40%;
    animation-delay: 1s;
    animation-duration: 10s;
    opacity: 0.1;
  }
  
  .bubble--3 {
    width: 10px;
    height: 10px;
    left: 30%;
    animation-delay: 5s;
    animation-duration: 20s;
    opacity: 0.3;
  }
  
  .bubble--4 {
    width: 25px;
    height: 25px;
    left: 40%;
    animation-delay: 8s;
    animation-duration: 17s;
    opacity: 0.2;
  }
  
  .bubble--5 {
    width: 30px;
    height: 30px;
    left: 60%;
    animation-delay: 10s;
    animation-duration: 15s;
    opacity: 0.1;
  }
  
  .bubble--6 {
    width: 10px;
    height: 10px;
    left: 80%;
    animation-delay: 3s;
    animation-duration: 30s;
    opacity: 0.4;
  }
  
  .bubble--7 {
    width: 15px;
    height: 15px;
    left: 90%;
    animation-delay: -7s;
    animation-duration: 25s;
    opacity: 0.3;
  }
  
  .bubble--9 {
    width: 20px;
    height: 20px;
    left: 50%;
    bottom: 30px;
    animation-delay: -5s;
    animation-duration: 19s;
    opacity: 0.2;
  }
  
  .bubble--10 {
    width: 40px;
    height: 40px;
    left: 30%;
    bottom: 30px;
    animation-delay: -21s;
    animation-duration: 16s;
    opacity: 0.3;
  }
  
  .bubble--11 {
    width: 30px;
    height: 30px;
    left: 60%;
    bottom: 30px;
    animation-delay: -13.75s;
    animation-duration: 20s;
    opacity: 0.3;
  }
  
  .bubble--11 {
    width: 25px;
    height: 25px;
    left: 90%;
    bottom: 30px;
    animation-delay: -10.5s;
    animation-duration: 19s;
    opacity: 0.3;
  }
  
  #page5 {
    background-image: url('../../assets/parallex/bg/Layer-2\ 3.jpg');
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    /* display: flex; */
    /* justify-content: center; */
    overflow: hidden;
    position: relative;
  }

  #page6 {
    background: #071736;
  }

  #page7 {
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      overflow: hidden;
      position: relative;
      /* align-items: center; */
      flex-direction: column;
      background-color: #071736;
}

 
  .tressure {
    position: absolute;
    bottom: 0%;
    left: 25%;
    transform: translate3d(-50%, -50%, 0);
    justify-content: start;
    margin-left: 25px;
    z-index: 5;
  }

  .tressure1 {
    position: absolute;
    bottom: 0%;
    right: 10%;
    transform: translate3d(-50%, -50%, 0);
    justify-content: start;
    margin-left: 25px;
    z-index: 4;
}

.tressure2 {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  justify-content: start;
  margin-left: 25px;
  z-index: 4;
}

  

  .silverCoral-container {
    position: absolute;
    bottom: 0;
    /* height: 184px; */
    /* right: 100px; */
    height: 300px;
    /* right: 100px; */
    width: 100%;
  }
  .silverCoral-block {
    position: absolute;
    bottom: 0;
    height: 300px;
    right: 100px;
    width: 300px;
  }
  img.silverCoral {
    position: absolute;
    height: 175px;
    bottom: 0;
    right: -34px;
}

.ball {
  animation: float 3.5s ease-in-out infinite;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    position: relative;
    background: radial-gradient(circle at 75% 30%, white 5px, aqua 8%, darkblue 60%, aqua 100%);
    box-shadow: inset 0 0 20px #fff, inset 10px 0 46px #eaf5fc, inset 88px 0px 60px #c2d8fe, inset -20px -60px 100px #fde9ea, inset 0 50px 140px #fde9ea, 0 0 90px #fff;
    position: absolute;
    bottom: 17%;
    right: 85px;
    z-index: 1;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-40px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes swim-scuba
{
    0% {margin-left: -235px;}
    70% {margin-left: 100%;}
    100% {margin-left: 100%;}
}

img.fishImage {
  width: 150px;
  position: absolute;
    z-index: 1;
    top: 25%;
    right: 0;
  animation: swim-fish 20s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-delay: 1s;
}


img.fishImage7 {
  width: 100px;
  position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;
    transform: scale(-1, 1);
  animation: swim 20s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-delay: 3s;
}

img.fishImage10 {
  width: 100px;
  position: absolute;
    z-index: 2;
    bottom: 10%;
    right: 0;
  animation: swim-fish-odd 20s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
}

img.tortoise {
  width: 200px;
  position: absolute;
  top: 30%;
  right: 0;
  animation: swim-tortoise 20s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      z-index: -1;
}

img.page4-title4, img.page4-title5 {
  height: 500px;
}

@keyframes swim-fish
{
    0% {margin-right: -235px;}
    70% {margin-right: 100%;}
    100% {margin-right: 100%;}
}

@keyframes swim-fish-odd {
  0% {margin-right: -235px;}
  70% {margin-right: 100%; }
  100% {margin-right: 100%; }
}

@keyframes swim-tortoise {
  0% {margin-right: -235px; }
  70% {margin-right: 100%;}
  100% {margin-right: 100%;}
}

.bubblewhirl {
  width: 100px;
  height: 100px;
  background: #6bb0ff;
  border: 9px solid #9ecbff;
  position: absolute;
  top: 60%;
  left: 10%;
  overflow: visible;
  border-radius: 48% 40% 62% 47%/61% 49% 64% 43%;
  animation: rotate 35s infinite linear;
  z-index: 1;
}

@keyframes rotate {
  0% {
    transform: rotate(1turn);
  }
}
.bubblewhirl:before {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  width: calc(100% - (100px * 0.3));
  height: calc(100% - (100px * 0.3));
  background: #057aff;
  border: 6.5px solid #3895ff;
  border-radius: 41% 40% 50% 55%/49% 52% 51% 43%;
  z-index: -2;
  animation: rotateBefore 35s infinite linear;
}

@keyframes rotateBefore {
  0% {
    transform: rotate(-2turn);
  }
}
.bubblewhirl:after {
  content: "";
  position: absolute;
  top: 20px;
  left: 20px;
  width: calc(100% - (50px));
  height: calc(100% - (50px));
  background: #004a9e;
  border: 5px solid #0062d1;
  border-radius: 42% 63% 51% 60%/47% 62% 42% 52%;
  animation: rotateAfter 35s infinite linear;
}

@keyframes rotateAfter {
  0% {
    transform: rotate(2turn);
  }
}

.bubble-container #bubble {
  position: relative;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: white;
  background: linear-gradient(to left, #23679c, #1775be);
  box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.2);
  animation: rise 7s linear;
  animation-iteration-count: 3;
  animation-delay: 2s;
  animation-timing-function: ease-in-out;
}
.bubble-container #bubble:before {
  content: "";
  position: absolute;
  display: block;
  top: 5px;
  left: 7px;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background: #3172a5;
  opacity: 0.8;
  overflow: hidden;
}
.bubble-container #bubble:after {
  content: "";
  position: absolute;
  display: block;
  top: 25px;
  left: 70px;
  height: 25px;
  width: 9px;
  border-radius: 50%;
  background: white;
  overflow: hidden;
}
.bubble-container #bubble2 {
  position: relative;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: white;
  background: linear-gradient(to left, #23679c, #1775be);
  box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.2);
  animation: linear rise 4s;
  animation-delay: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 3;
  margin: 30px;
  margin-top: 160px;
}
.bubble-container #bubble2:before {
  content: "";
  position: absolute;
  display: block;
  top: 5px;
  left: 7px;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background: #3172a5;
  opacity: 0.8;
  overflow: hidden;
}
.bubble-container #bubble2:after {
  content: "";
  position: absolute;
  display: block;
  top: 15px;
  left: 40px;
  height: 10px;
  width: 5px;
  border-radius: 50%;
  background: white;
  overflow: hidden;
}
.bubble-container #bubble3 {
  position: relative;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: white;
  background: linear-gradient(to left, #23679c, #1775be);
  box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.2);
  animation: linear rise2 5s;
  animation-delay: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 3;
  margin: 30px;
  margin-top: 200px;
}
.bubble-container #bubble3:before {
  content: "";
  position: absolute;
  display: block;
  top: 5px;
  left: 7px;
  height: 23px;
  width: 23px;
  border-radius: 50%;
  background: #3172a5;
  opacity: 0.8;
  overflow: hidden;
}
.bubble-container #bubble3:after {
  content: "";
  position: absolute;
  display: block;
  top: 15px;
  left: 24px;
  height: 8px;
  width: 4px;
  border-radius: 50%;
  background: white;
  overflow: hidden;
}
.bubble-container #bubble4 {
  position: relative;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: white;
  background: linear-gradient(to left, #23679c, #1775be);
  box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.2);
  animation: linear rise2 4s;
  animation-delay: 5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 3;
}
.bubble-container #bubble4:before {
  content: "";
  position: absolute;
  display: block;
  top: 5px;
  left: 7px;
  height: 23px;
  width: 23px;
  border-radius: 50%;
  background: #3172a5;
  opacity: 0.8;
  overflow: hidden;
}
.bubble-container #bubble4:after {
  content: "";
  position: absolute;
  display: block;
  top: 15px;
  left: 25px;
  height: 8px;
  width: 4px;
  border-radius: 50%;
  background: white;
  overflow: hidden;
}
@keyframes rise {
  0% {
    transform: translatey(0px) scale(1);
  }
  15% {
    transform: translatey(-50px) scale(1.1);
  }
  45% {
    transform: translatey(-100px) scale(1.3);
  }
  75% {
    transform: translatey(-150px) scale(1.4);
  }
  100% {
    transform: translatey(-200px) scale(1.4);
    opacity: 0;
  }
}
@keyframes rise2 {
  0% {
    transform: translatey(0px) scale(1);
  }
  15% {
    transform: translatey(-50px) scale(1.1) rotate(90deg);
  }
  45% {
    transform: translatey(-100px) scale(1.5) rotate(250deg);
  }
  75% {
    transform: translatey(-150px) scale(2) rotate(300deg);
  }
  100% {
    transform: translatey(-200px) scale(2) rotate(360deg);
    opacity: 0;
  }
}

.bubble-container1 {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 50%;
  left: 0;
  z-index: -2
}

.bubble-container2 {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 50%;
  right: 0;
  z-index: -2;
}


svg{
  height:200px;

}
/* 
.content{
 position: absolute;
  top: 70%;
  left: 100%;
  transform: translate(-70%, -50%);
  display:block;
} */

.bubble {
    width: 30px;
    height: 30px;
    background: lightblue;
    border-radius: 200px;
    -moz-border-radius: 200px;
    -webkit-border-radius: 200px;
    position: absolute;
}

.x1 {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 0.2;
    -webkit-animation: moveBubblesX 15s linear infinite, moveBubblesY 6s ease-in-out infinite alternate;
    -moz-animation: moveBubblesX 15s linear infinite, moveBubblesY 6s ease-in-out infinite alternate;
    -o-animation: moveBubblesX 15s linear infinite, moveBubblesY 6s ease-in-out infinite alternate;
}

.x2 {
    left: 200px;
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 0.5;
    -webkit-animation: moveBubblesX 25s linear infinite, moveBubblesY 8s ease-in-out infinite alternate;
    -moz-animation: moveBubblesX 25s linear infinite, moveBubblesY 8s ease-in-out infinite alternate;
    -o-animation: moveBubblesX 25s linear infinite, moveBubblesY 8s ease-in-out infinite alternate;
}
.x3 {
    left: 350px;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.3;
    -webkit-animation: moveBubblesX 20s linear infinite, moveBubblesY 4s ease-in-out infinite alternate;
    -moz-animation: moveBubblesX 20s linear infinite, moveBubblesY 4s ease-in-out infinite alternate;
    -o-animation: moveBubblesX 20s linear infinite, moveBubblesY 4s ease-in-out infinite alternate;
}

.x4 {
    left: 470px;
    -webkit-transform: scale(0.75);
    -moz-transform: scale(0.75);
    transform: scale(0.75);
    opacity: 0.35;
    -webkit-animation: moveBubblesX 18s linear infinite, moveBubblesY 3s ease-in-out infinite alternate;
    -moz-animation: moveBubblesX 18s linear infinite, moveBubblesY 3s ease-in-out infinite alternate;
    -o-animation: moveBubblesX 18s linear infinite, moveBubblesY 3s ease-in-out infinite alternate;
}
.x5 {
    left: 150px;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.3; 
    -webkit-animation: moveBubblesX 17s linear infinite, moveBubblesY 5s ease-in-out infinite alternate;
    -moz-animation: moveBubblesX 17s linear infinite, moveBubblesY 5s ease-in-out infinite alternate;
    -o-animation: moveBubblesX 17s linear infinite, moveBubblesY 5s ease-in-out infinite alternate;
}

.x6 {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 0.2;
    -webkit-animation: moveBubblesX 15s linear infinite, moveBubblesYOpposite 4s ease-in-out infinite alternate;
    -moz-animation: moveBubblesX 15s linear infinite, moveBubblesYOpposite 4s ease-in-out infinite alternate;
    -o-animation: moveBubblesX 15s linear infinite, moveBubblesYOpposite 4s ease-in-out infinite alternate;
}

.x7 {
    left: 200px;
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 0.5;
    -webkit-animation: moveBubblesX 21s linear infinite, moveBubblesYOpposite 3s ease-in-out infinite alternate;
    -moz-animation: moveBubblesX 21s linear infinite, moveBubblesYOpposite 3s ease-in-out infinite alternate;
    -o-animation: moveBubblesX 21s linear infinite, moveBubblesYOpposite 3s ease-in-out infinite alternate;
}
.x8 {
    left: 350px;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.3;
    -webkit-animation: moveBubblesX 16s linear infinite, moveBubblesYOpposite 6s ease-in-out infinite alternate;
    -moz-animation: moveBubblesX 16s linear infinite, moveBubblesYOpposite 6s ease-in-out infinite alternate;
    -o-animation: moveBubblesX 16s linear infinite, moveBubblesYOpposite 6s ease-in-out infinite alternate;
}

.x9 {
    left: 470px;
    -webkit-transform: scale(0.75);
    -moz-transform: scale(0.75);
    transform: scale(0.75);
    opacity: 0.35;
    -webkit-animation: moveBubblesX 24s linear infinite, moveBubblesYOpposite 5s ease-in-out infinite alternate;
    -moz-animation: moveBubblesX 24s linear infinite, moveBubblesYOpposite 5s ease-in-out infinite alternate;
    -o-animation: moveBubblesX 24s linear infinite, moveBubblesYOpposite 5s ease-in-out infinite alternate;
}
.x10 {
    left: 150px;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.3; 
    -webkit-animation: moveBubblesX 17s linear infinite, moveBubblesYOpposite 5s ease-in-out infinite alternate;
    -moz-animation: moveBubblesX 17s linear infinite, moveBubblesYOpposite 5s ease-in-out infinite alternate;
    -o-animation: moveBubblesX 17s linear infinite, moveBubblesYOpposite 5s ease-in-out infinite alternate;
}

#bubbles { 
  height: 100%;
  padding: 150px 0;
}


@keyframes MoveUpDown {
     0% {transform: none; margin-left: -235px;}
    25% {transform:  translateY(-50px) translateX(20px) rotate(5deg);}
    50% {transform:  translateY(25px) translateX(-25px) rotate(-5deg);}
    75% {transform:  translateY(-50px) translateX(25px) rotate(-5deg); margin-left: 0}
    100% {transform:  translateY(0px) translateX(0px) rotate(0deg); margin-left: 0}
}

@-webkit-keyframes moveBubblesX { 
    0% { 
        left: -1%;
    }
    100% { 
        left: 101%;
    }
}

@-webkit-keyframes moveBubblesY { 
    0% { 
        margin-top:0px;
    }
    100% { 
        margin-top:150px;
    }
}

@-webkit-keyframes moveBubblesYOpposite { 
    0% { 
        margin-top:0px;
    }
    100% { 
        margin-top:-100px;
    }
}
img.submar {
  height: 150px;
    animation: MoveUpDown 10s easeInOut infinite;
    -webkit-animation: MoveUpDown 10s ease-In-Out infinite;
    transform-origin: 50% 50%;
    position: absolute;
    top: 0%;
    right: 20%;
}

img.page2-plant1 {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
img.page2-plant2 {
  position: absolute;
    bottom: 0;
    width: 400px;
    z-index: -1;
    right: 0;
}

img.octopus {
  width: 300px;
  position: absolute;
  bottom: 20%;
  left: 0;
}

img.fishImage13 {
  position: absolute;
  left: 20%;
  top: 50%;
  width: 300px;
}

img.page4-plant1 {
  position: absolute;
    bottom: 0;
    width: 100%;
    height: 50%;
    left: 0;
}

img.page3-plant1 {
  position: absolute;
    bottom: 0;
    width: 100%;
    height: 50%;
    z-index: -2;
    left: 0;
}

img.page3-plant2 {
  position: absolute;
    bottom: -10%;
    width: 100%;
    height: 50%;
    z-index: -2;
    right: 0;
}

img.page3-plant3 {
  position: absolute;
    bottom: 0;
    width: 500px;
    /* height: 50%; */
    z-index: -2;
    right: -10%;
}

img.page3-plant4 {
  position: absolute;
    bottom: 0;
    width: 500px;
    /* height: 50%; */
    z-index: -2;
    left: -5%;
}

img.page3-plant5 {
  position: absolute;
    bottom: 0;
    width: 500px;
    /* height: 50%; */
    z-index: -2;
    left: 50%;
}
img.seahorse {
  position: absolute;
  right: 0;
  bottom: 40%;
  width: 200px;
  
}

.ocean {
  /* height: 5%; */
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #13568a;
}

.wave {
  background: url('../../assets/parallex/bg/wave.svg') repeat-x;
  position: absolute;
  width: 6400px;
  top: -198px;
  left: 0;
  height: 198px;
  transform: translate(0, 0, 0);
  animation: wave 7s ease infinite;
}

.wave:nth-of-type(2) {
  top: -168px;
  animation: swell 4s ease infinite;
  opacity: 1;
}

@keyframes wave {
  0% { margin-left: 0; }
  100% { margin-left: -1600px;}
}
@keyframes swell {
  0%, 100% {
    transform: translate(0, -30px);
  }
  50% {
    transform: translate(0, 5px);
  }
}

.mountains {
  background-image: url('../../assets/parallex/bg/mountain.png');
  height: 350px;
    width: 100%;
    position: absolute;
    bottom: 25%;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-size: 175%;
}

Iam {
  padding: 2em 5em;
  font: normal 40px/50px Montserrat, sans-serif;
  color: #999;
}
.Iam p {
  height: 50px;
  float: left;
  margin-right: 0.3em;
}
.Iam b {
  float: left;
  overflow: hidden;
  position: relative;
  height: 35px;
}
.Iam .innerIam {
  display: inline-block;
  color: #FFFFFF;
  position: relative;
  white-space: nowrap;
  top: 0;
  left: 0;
  font-size: 30px;
  text-align: center;
/*animation*/
-webkit-animation:move 10s;
   -moz-animation:move 10s;
    -ms-animation:move 10s;
     -o-animation:move 10s;
        animation:move 10s;
/*animation-iteration-count*/
-webkit-animation-iteration-count:infinite;
   -moz-animation-iteration-count:infinite;
    -ms-animation-iteration-count:infinite;
     -o-animation-iteration-count:infinite;
        animation-iteration-count:infinite;
/*animation-delay*/
-webkit-animation-delay:1s;
   -moz-animation-delay:1s;
    -ms-animation-delay:1s;
     -o-animation-delay:1s;
        animation-delay:1s;
}
@keyframes move{
0%  { top: 0px; }
20% { top: -35px; }
40% { top: -70px; }
60% { top: -105px; }
80% { top: -px; }
}

.dummbg{
  position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
}

.mountain-2 {
  position: absolute;
    left: -5px;
    bottom: -20px;
    top: 0;
    /* height: 100%; */
    z-index: 2;
}

.mountain-1 {
  position: absolute;
    right: 0%;
    bottom: 0px;
    top: 0;
    /* height: 100%; */
    z-index: 1;
}

img.star-fish {
  position: absolute;
  width: 100px;
  bottom: 10%;
  right: 50%;
  z-index: 1;
}

.land {
  position: absolute;
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: 400px; 
}

img.corals {
  position: absolute;
  bottom: 0;
  right: 10%;
  width: 200px;
  z-index: 2;
}

img.corals1 {
  position: absolute;
  bottom: 0;
  left: 10%;
  width: 200px;
  z-index: 2;
}

img.greencoral {
  position: absolute;
  width: 100px;
  z-index: 2;
  top: 30%;
  left: 0;
}

img.plant2 {
  position: absolute;
  width: 100px;
  z-index: 1;
  top: 40%;
  right: 0;
}

img.shell {
  width: 30px;
  position: absolute;
  bottom: 0;
  z-index: 1;
  right: 60%;
}

img.shell2 {
  position: absolute;
  width: 100px;
  top: 50%;
  z-index: 3;
}

img.plant3 {
  position: absolute;
    width: 100px;
    z-index: 2;
    top: 10%;
    left: 0%;
}

img.backbg {
  bottom: 200px;
    position: absolute;
    z-index: 0;
    width: 100%;
}

.title, .title2{
  z-index: 5;
  color: #fff;
}

.mascotPanel h1, .faqPanel h1 {
  margin: 0;
  color: #fff;
}

.mascotPanel, .faqPanel {
  z-index: 5
}

.page4 h1 {
  margin: 0;
}

.page1 h1 {
  color: #fff;
}