


.rulesCarousel {
  width: 99vw;
  height: calc(100vh - 90px);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* background-image: linear-gradient(45deg, #8B5CF6, #EC4899); */
  font-family: "Roboto", sans-serif;
}
.rulesCarousel .carousel {
  overflow: unset;
  position: relative;
  width: 23rem;
  height: 80%;
  perspective: 500px;
  transform-style: preserve-3d;
  border-radius: 15px;
  box-shadow: none;
}  
.card-container {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateY(calc(var(--offset) * 50deg)) scaleY(calc(1 + var(--abs-offset) * -0.4)) translateZ(calc(var(--abs-offset) * -30rem)) translateX(calc(var(--direction) * -5rem));
  filter: blur(calc(var(--abs-offset) * 1rem));
  transition: all 0.3s ease-out;
}

.rulesCarousel .card p {
  font-size: 25px;
  line-height: 1.5;
}


.card {
  width: 100%;
  height: 100%;
  padding: 2rem;
  background-color: hsl(280deg, 40%, calc(100% - var(--abs-offset) * 50%));
  border-radius: 1rem;
  color: #9CA3AF;
  text-align: justify;
  transition: all 0.3s ease-out;
  border: none;
}
.card h2 {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin: 0 0 0.7em;
  color: #1F2937;
}
.card p, .card h2 {
  transition: all 0.3s ease-out;
  opacity: var(--active);
}
.carNav {
  color: white;
  font-size: 5rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  z-index: 2;
  cursor: pointer;
  user-select: none;
  background: unset;
  border: unset;
  width: 100px;
}
.carNav.left {
  transform: translateX(-100%) translatey(-50%);
}
.carNav.right {
  right: 0px;
  transform: translateX(100%) translatey(-50%);
}


@media (max-width: 991px) {
  .carNav.left {
    transform: translateX(-65%) translatey(-50%);
  }
  .carNav.right {
    right: 35px;
    transform: translateX(100%) translatey(-50%);
  }
  .about {
    flex-direction: column-reverse;
  }
}